export const DropDownDivider = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '3px 0 10px 0',
        }}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="74"
            height="2"
            viewBox="0 0 74 2"
            fill="none"
        >
            <path
                d="M1.21777 1H72.335"
                stroke="#E1E6EF"
                stroke-width="2"
                stroke-linecap="round"
            />
        </svg>
    </div>
)
