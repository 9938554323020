import React from 'react'
import { AppBar, Container, Toolbar, useScrollTrigger } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import CheckoutBreadcrumbs from '../Components/CheckoutBreadcrumbs'
import Hidden from '@material-ui/core/Hidden'

const useStyles = makeStyles((theme) => ({
    header: {
        background: '#FFF',
        borderBottom: `5px solid #592163`,
    },
    headerInnerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.up('lg')]: {
            padding: `0 ${theme.spacing(2)}px`,
        },
    },
    brandLogo: {
        [theme.breakpoints.up('sm')]: {
            width: 300,
            height: 50,
        },
    },
    continueShoppingLink: {
        display: 'flex',
        alignContent: 'center',
        fontSize: '0.88rem',
        color: 'rgb(0,92,89)',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.94rem',
        },
        textTransform: 'none',
    },
}))

export const CHECKOUT_STEP_LOGIN = 'login'
export const CHECKOUT_STEP_PAYMENT = 'payment'

const Header = (props) => {
    const classes = useStyles()
    const { baseUrl, logoImageUrl, user, showGuestCheckout } = props
    const isVerificationStep =
        typeof window !== 'undefined' &&
        location.pathname.endsWith('/phone-verification/')
    return (
        <AppBar position="relative" className={classes.header} elevation={0}>
            <Toolbar>
                <Container className={classes.headerInnerWrapper}>
                    <a href={baseUrl} title="Back to homepage">
                        <img
                            className={classes.brandLogo}
                            src={logoImageUrl}
                            alt={'Brand Logo'}
                            width={145}
                            height={32}
                        />
                    </a>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            href={`${baseUrl}/buy-gift-cards/`}
                            className={classes.continueShoppingLink}
                            variant={'outlined'}
                            size={'large'}
                            fullWidth={true}
                        >
                            Continue shopping
                        </Button>
                    </div>
                    <Hidden smDown>
                        <div>
                            <CheckoutBreadcrumbs
                                currentStep={
                                    user?.is_logged_in ||
                                    showGuestCheckout ||
                                    isVerificationStep
                                        ? CHECKOUT_STEP_PAYMENT
                                        : CHECKOUT_STEP_LOGIN
                                }
                            />
                        </div>
                    </Hidden>
                </Container>
            </Toolbar>
        </AppBar>
    )
}

export default Header
