import axiosClient from './axiosClient'
import { CLOUDINARY_IMAGE_UPLOAD_URL, CONSUMER_CART_SOURCE } from './constants'
import { isNull } from 'lodash'
import { smartyStreetsValidate } from './components/SmartyStreetsValidate'
import { CART_SOURCE } from './components/SmallBusiness/SmbConstants'

export const getUserAndPaymentMethods = async ({ queryKey }) => {
    const { data: user } = await axiosClient.get(`/ajax/check-login/`, {
        params: { pathName: window.location.pathname },
    })
    const { data: paymentMethods } = await axiosClient.get(
        `/ajax/payment-methods/`
    )
    return { user, paymentMethods }
}

export const getUserPaymentMethods = async () => {
    const { data } = await axiosClient.get(`/ajax/payment-methods/`)
    return data
}

export const getCart = async () => {
    const { data } = await axiosClient.get(`/ajax/cart/`)
    return data
}

export const getFeaturedMerchants = async () => {
    const { data } = await axiosClient.get(`/ajax/cart/featured-merchants/`)
    return data
}

export const updateCartContents = async (payload) => {
    const { data } = await axiosClient.put(`/ajax/cart/`, payload)
    return data
}

export const postPaymentMethod = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/account/payment/`, payload)
    return data
}

export const deletePaymentMethod = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/account/delete-payment/`,
        payload
    )
    return data
}

export const postPaymentIntent = async (payload) => {
    return await axiosClient.post(`/ajax/cart/payment-intent/`, payload)
}

export const cancelPaymentIntent = async (payload) => {
    return await axiosClient.post(`/ajax/cart/cancel-payment-intent/`, payload)
}

export const postConsumerOrder = async (payload) => {
    // source must be consumer
    payload['source'] = CONSUMER_CART_SOURCE
    const { data } = await axiosClient.post(`/ajax/create-order/`, payload)
    return data
}

export const postSmbOrder = async (payload) => {
    payload['source'] = CART_SOURCE
    const { data } = await axiosClient.post(`/ajax/create-smb-order/`, payload)
    return data
}

export const postPaypalOrder = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/cart/create-paypal-order/`,
        payload
    )
    return data
}

export const getOpenLoopProductCategories = async ({ queryKey }) => {
    const [_key] = queryKey
    const { data } = await axiosClient.get(
        `/ajax/open-loop-product-categories/`
    )
    return data
}

export const getOpenLoopProducts = async ({ queryKey }) => {
    const [
        _key,
        {
            includePlastic,
            includeVirtual,
            cardNetwork,
            limit = 100,
            page = 1,
            category = '',
            isSmb = 0,
        },
    ] = queryKey
    const { data } = await axiosClient.get(
        `/ajax/open-loop-products/?include_plastic_cards=${includePlastic}&include_virtual_cards=${includeVirtual}&card_network=${cardNetwork}&category=${category}&limit=${limit}&page=${page}&smb=${isSmb}`
    )
    return data
}

export const postFilterBadWords = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/check-for-bad-words/`,
        payload
    )
    return data
}

export const postValidateAddress = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/validate-address/`, payload)
    return data
}

export const postCheckPhoneDeliverability = async (payload) => {
    const { data } = await axiosClient.post(`/verify/deliverability/`, payload)
    return data
}

export const smartyValidateAddress = async (payload) => {
    let addressData = {
        'address-1': payload.address_line1,
        'address-2': payload.address_line2,
        locality: payload.city,
        region: payload.state,
        'postal-code': payload.zip_code,
    }
    let addressValidation = smartyStreetsValidate(addressData)
    return !isNull(addressValidation) && addressValidation
}

export const postAddCardsToCart = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/cart/`, payload)
    return data
}

export const getOccasions = async () => {
    const { data } = await axiosClient.get(
        `/ajax/gift-cards-for-all-occasions/`
    )
    return data
}

export const getGiftTemplates = async ({ queryKey }) => {
    const [_key, { page, limit, smb }] = queryKey
    const { data } = await axiosClient.get(
        `/ajax/gift-templates/?page=${page}&limit=${limit}&smb=${smb}&ond=1`
    )
    return data
}

export const getGiftTemplateCategories = async ({ queryKey }) => {
    const [_key] = queryKey
    const { data } = await axiosClient.get(`/ajax/gift-template-categories/`)
    return data
}

export const getDesignCategories = async () => {
    const { data } = await axiosClient.get(`/ajax/design-categories/`)
    return data
}

export const getGreetingCards = async ({ queryKey }) => {
    const [_key, { category, page, limit }] = queryKey
    const { data } = await axiosClient.get(
        `/ajax/greeting-cards/?category=${category}&page=${page}&limit=${limit}`
    )
    return data
}

export const getFailedEmailRecipient = async ({ queryKey }) => {
    const [_key, { hash }] = queryKey
    const { data } = await axiosClient.get(
        `/ajax/get-failed-email-recipient/${hash}/`
    )
    return data
}

export const updateFailedEmailRecipient = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/update-failed-email-recipient/${payload.hash}/`,
        { new_recipient_email: payload.new_recipient_email }
    )
    return data
}

export const postApplyPromoCode = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/apply-promo-code/`, payload)
    return data
}

export const postRemovePromoCode = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/remove-promo-code/`, payload)
    return data
}

export const postNewsletterSignup = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/newsletter-signup/`, payload)
    return data
}

export const postActivateCard = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/activate/`, payload)
    return data
}

export const postCheckBalance = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/check-balance/`, payload)
    return data
}

export const postLogPaymentFailure = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/log-payment-failure/`,
        payload
    )
    return data
}

export const postLogPaypalFailureAuthorization = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/log-paypal-failure-auth/`,
        payload
    )
    return data
}
/**
 *
 * @param payload
 * @returns {Promise<*>}
 */
export const postValidateFedExShippingMethodsForAddresses = async (payload) => {
    const { addressesToCheck, currentFedExShippingMethodExternalId } = payload

    const result = await Promise.allSettled(
        addressesToCheck.map(async (address) => {
            try {
                const {
                    data: { available_shipping_methods },
                } = await axiosClient.post(
                    `/ajax/available-fedex-shipping-methods/`,
                    address,
                    {
                        timeout: 4000,
                    }
                )

                return {
                    isUnavailable: !available_shipping_methods.includes(
                        currentFedExShippingMethodExternalId
                    ),
                    shipToAddress: address,
                    availableShippingMethods: available_shipping_methods,
                }
            } catch (err) {
                console.error(
                    `Error getting available FedEx shipping methods for address`,
                    { address }
                )
            }
        })
    )

    return result
        .filter((item) => item.status === 'fulfilled')
        .map((item) => {
            return item.value
        })
}

export const postFetchFedExShippingMethodsForSingleAddress = async (
    payload
) => {
    const { data } = await axiosClient.post(
        `/ajax/available-fedex-shipping-methods/`,
        payload
    )

    return data
}

export const getMenuData = async () => {
    const { data } = await axiosClient.get(`/ajax/menu-data/`)
    return data
}

export const getLoggedIn = async ({ queryKey }) => {
    const [_key, { isSmb = false }] = queryKey
    const { data } = await axiosClient.get(`/ajax/check-login/?isSmb=${isSmb}`)
    return data
}

export const postRedeemGift = async ({ hash, email }) => {
    const { data } = await axiosClient.post(`/ajax/redeem-gift/`, {
        hash,
        recipient_email: email,
    })
    return data
}

export const getGypGift = async ({ queryKey }) => {
    const [_key, { uuid }] = queryKey
    const { data } = await axiosClient.get(`/ajax/get-gyp-gift/${uuid}/`)
    return data
}

export const postRedeemGypGift = async ({ uuid, email }) => {
    const { data } = await axiosClient.post(`/ajax/redeem-linked-gift/`, {
        uuid,
        recipient_email: email,
    })
    return data
}

export const postRedeemGypGiftAsEGift = async ({ uuid }) => {
    const { data } = await axiosClient.post(
        `/ajax/redeem-linked-gift-as-egift/`,
        {
            uuid,
        }
    )
    return data
}

export const postVirtualExchangeCheck = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/can-exchange-virtual-for-physical/`,
        payload
    )
    return data
}

export const postSubmitVirtualExchange = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/exchange-virtual-for-physical/`,
        payload
    )
    return data
}

export const getFavoriteData = async ({ queryKey }) => {
    const [_key, { merchantId }] = queryKey
    const { data } = await axiosClient.get(
        `/ajax/favorite-merchants/${merchantId}/`
    )
    return data
}

export const postFavoriteData = async (merchantId) => {
    const { data } = await axiosClient.post(
        `/ajax/favorite-merchants/${merchantId}/`,
        merchantId
    )
    return data
}

export const removeFavoriteData = async (merchantId) => {
    const { data } = await axiosClient.delete(
        `/ajax/favorite-merchants/${merchantId}/`
    )
    return data
}

export const postRatingData = async (payload) => {
    const { data } = await axiosClient.post(
        `/account/post-merchant-rating/`,
        payload
    )
    return data
}

export const postIDologyCheckUser = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/idology/check-user/`,
        payload
    )
    return data
}

export const postIDologyValidateCustomer = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/idology/validate-customer/`,
        payload
    )
    return data
}

export const postIDologySendAnswers = async ({
    transactionNumber,
    payload,
}) => {
    const { data } = await axiosClient.post(
        `/ajax/idology/send-answers/${transactionNumber}/`,
        payload
    )
    return data
}

export const postUploadImageToCloudinary = async (payload) => {
    const uploadResponse = await fetch(CLOUDINARY_IMAGE_UPLOAD_URL, {
        method: 'POST',
        body: payload,
    })
    if (uploadResponse && uploadResponse.ok) {
        return await uploadResponse.json()
    }
    return null
}

export const getImageRulesResult = async (imageUrl) => {
    imageUrl = imageUrl.replace(/^https?:\/\//, '')
    const { data } = await axiosClient.get(
        `/ajax/check-image-rules/?imageUrl=${imageUrl}`
    )
    return data
}

export const getOrder = async (orderNumber) => {
    const { data } = await axiosClient.get(
        `/account/orders/ajax/${orderNumber}/`
    )
    return data
}

export const getOrderByPlainTextHash = async ({ queryKey }) => {
    const [_key, { plainTextHash }] = queryKey
    const { data } = await axiosClient.get(
        `/ajax/smb-order-by-hash/${plainTextHash}/`
    )
    return data
}

export const postRefundRequest = async ({ orderNumber, payload }) => {
    const { data } = await axiosClient.post(
        `/account/orders/${orderNumber}/request-refund/`,
        payload
    )
    return data
}

export const cancelOrder = async (payload) => {
    const { data } = await axiosClient.post(
        `/account/orders/ajax/${payload.orderNumber}/cancel/`,
        payload
    )
    return data
}

export const postUserLogin = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/user-login/`, payload)
    return data
}

export const postCreateGuestAccount = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/guest-checkout/`, payload)
    return data
}

export const postSmbRegistration = async (payload) => {
    const { data } = await axiosClient.post(
        `/ajax/submit-small-business-registration/`,
        payload
    )
    return data
}

export const getCobrands = async ({ queryKey }) => {
    const [_key, { page, limit }] = queryKey

    const { data } = await axiosClient.get(
        `/ajax/user-cobrands/${page ? `?page=${page}` : ''}${
            page && limit ? `&limit=${limit}` : ''
        }`
    )
    return data
}

export const postSubmitCobrand = async (payload) => {
    const { data } = await axiosClient.post(`/ajax/submit-cobrand/`, payload)
    return data
}

export const getAuthorizeFiservSession = async () => {
    const { data } = await axiosClient.get(`/account/fiserv/authorize-session/`)
    return data
}

export const getCommerceHubCredentials = async () => {
    const { data } = await axiosClient.get(
        `/account/commerce-hub/get-credentials/`
    )
    return data
}

export const postInitCardLinkSession = async (payload) => {
    return await axiosClient.post(`/ajax/init-card-link-session/`, payload)
}

export const postKillCardLinkSession = async (payload) => {
    console.log('postKillCardLinkSession', { payload })

    return await axiosClient.post('/ajax/kill-card-link-session/', payload)
}

export const getCardLinkSessionEnrolledCards = async (queryKeys) => {
    console.log('getCardLinkSessionEnrolledCards', { queryKeys })

    const cardLinkSessionId = queryKeys?.queryKey[1][0]

    return await axiosClient.post(
        '/ajax/get-enrolled-redemption-accounts/',
        {session_id: cardLinkSessionId}
    )
}


export const postCardLinkActivate = async ({companyId, cardLinkSessionId, giftId, redemptionAccountId}) => {

    console.log('postCardLinkActivate', {companyId, cardLinkSessionId, giftId, redemptionAccountId})

    return await axiosClient.post(
        '/ajax/link-card-and-redemption-account/',
        {session_id: cardLinkSessionId, gift_id: giftId, redemption_account: redemptionAccountId}
    )
}

export const postCardLinkDeactivate = async ({giftId}) => {

    console.log('postCardLinkDeactivate', {giftId})

    return await axiosClient.post(
        '/ajax/unlink-card/',
        {gift_id: giftId}
    )
}

export const postCardUnenroll = async ({redemptionAccountId}) => {

    console.log('postCardUnenroll', {redemptionAccountId})

    return await axiosClient.post(
        '/ajax/unenroll-card/',
        {redemption_account: redemptionAccountId}
    )
}