import React from 'react'
import { Container, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    footer: {
        marginTop: 'auto',
        color: 'white',
        zIndex: 1,
        [theme.breakpoints.only('xs')]: {
            marginBottom: '9.84rem',
        },
    },
    footerTopBar: {
        padding: '1.5rem 0',
        backgroundColor: theme.palette.secondary.main,
        textAlign: 'center',
    },
    footerTopBar2: {
        padding: '1.5rem 0',
        backgroundColor: '#797979',
    },
    footerLink: {
        color: 'white',
        fontWeight: 'bold',
    },
}))

const CreateACardFooter = ({ removeFooterMargin, faqUrl }) => {
    const classes = useStyles()
    return (
        <footer
            className={classes.footer}
            style={removeFooterMargin ? { marginBottom: '0' } : null}
        >
            <div className={classes.footerTopBar}>
                <Container maxWidth="lg">
                    <Typography variant="h6">
                        Have questions?{' '}
                        <a
                            href={faqUrl}
                            className={classes.footerLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View our FAQs
                        </a>
                    </Typography>
                </Container>
            </div>
            <div className={classes.footerTopBar2}>
                <Container maxWidth="lg">
                    <Typography>
                        ©{new Date().getFullYear()}{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={'https://www.wolfe.com/'}
                            className={classes.footerLink}
                        >
                            A Wolfe, LLC Company
                        </a>{' '}
                        |{' '}
                        <a
                            href={'/terms/'}
                            data-layer-push-event="FooterClick|Footer|Click|Terms of Use"
                            className={classes.footerLink}
                        >
                            Terms of Use
                        </a>{' '}
                        |{' '}
                        <a
                            href={'/terms-cart/'}
                            data-layer-push-event="FooterClick|Footer|Click|Terms of Service"
                            className={classes.footerLink}
                        >
                            Terms of Service
                        </a>{' '}
                        |{' '}
                        <a
                            href={'/accessibility-statement/'}
                            data-layer-push-event="FooterClick|Footer|Click|Accessibility Statement"
                            className={classes.footerLink}
                        >
                            Accessibility Statement
                        </a>{' '}
                        |{' '}
                        <a
                            href={'/privacy-policy/'}
                            data-layer-push-event="FooterClick|Footer|Click|Privacy Policy"
                            className={classes.footerLink}
                        >
                            Privacy Policy
                        </a>{' '}
                        |{' '}
                        <a
                            href={'/cardholder-agreement/'}
                            data-layer-push-event="FooterClick|Footer|Click|Cardholder Agreement"
                            className={classes.footerLink}
                        >
                            Cardholder Agreement
                        </a>
                        <br />
                        <br />
                        The Gift Card Granny Visa&#174; Gift Card and the
                        Digital Visa eGift are issued by Sutton Bank&#174;,
                        Member FDIC, pursuant to a license from Visa U.S.A. Inc.
                        The Visa Gift Card can be used everywhere Visa debit
                        cards are accepted in the US. No cash or ATM access.
                        <br />
                        <br />
                        The Digital Visa eGift can be redeemed at internet
                        merchants everywhere Visa debit cards are accepted in
                        the US. No cash or ATM access. The Gift Card Granny
                        Mastercard&#174; Gift Card and Digital Mastercard eGift
                        are issued by Sutton Bank, Member FDIC, pursuant to a
                        license by Mastercard International Incorporated. Use
                        your Mastercard Gift Card and Digital Mastercard eGift
                        everywhere Debit Mastercard is accepted in the US.
                        Mastercard is a registered trademark, and the circles
                        design is a trademark of Mastercard International
                        Incorporated.
                    </Typography>
                </Container>
            </div>
        </footer>
    )
}

export default CreateACardFooter
