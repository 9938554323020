import {
    SMB_GET_ORDER_REQUEST,
    SMB_GET_ORDER_RESPONSE,
    SMB_REORDER_REQUEST,
    SMB_REORDER_RESPONSE,
} from '../actions/orderDetails'

const initialState = {
    reorderRequestLoading: false,
    reorderRequestError: null,
    reorderRequestSuccess: false,
    order: {
        transaction_amount: null,
        order_number: '',
        cancel_reason: '',
        date_created: '',
        date_approved: '',
        date_fulfilled: '',
        payment_method: {
            card_type: '',
            last_four: '',
            first_name: '',
            last_name: '',
            address: {
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                zip_code: '',
            },
        },
        delivery_groups: [],
    },
    getOrderError: null,
    orderStatus: '',
    canReorder: false,
    hasDownloadableCards: false,
    cards: [],
    refundItems: [],
    refundIssued: false,
    user: { email: '', phone: '', name: '' },
}

export default function orderDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case SMB_REORDER_REQUEST:
            return {
                ...state,
                reorderRequestLoading: true,
                reorderRequestError: null,
                reorderRequestSuccess: false,
            }
        case SMB_REORDER_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    reorderRequestError: action.payload,
                    reorderRequestLoading: false,
                    reorderRequestSuccess: false,
                }
            }
            return {
                ...state,
                reorderRequestLoading: false,
                reorderRequestError: null,
                reorderRequestSuccess: true,
            }
        case SMB_GET_ORDER_REQUEST:
            return {
                ...state,
                getOrderError: null,
            }
        case SMB_GET_ORDER_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    getOrderError: action.payload,
                }
            }
            return {
                ...state,
                order: action.payload.order,
                canReorder: action.payload.canReorder,
                cards: action.payload.cards,
                orderStatus: action.payload.status,
                hasDownloadableCards: action.payload.hasDownloadableCards,
                user: action.payload.user,
                refundIssued: action.payload.refundIssued,
                refundItems: action.payload.refunds,
            }
        default:
            return state
    }
}
