import React, { memo, useEffect, useMemo, useState } from 'react'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useTheme from '@material-ui/core/styles/useTheme'
import MobileMenu from './MobileMenu'
import DesktopMenu from './DesktopMenu'
import DarkenedViewport from './DarkenedViewport'
import isObject from 'lodash/isObject'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import useLoggedIn from '../../../hooks/useLoggedIn'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ExtoleBanner from '../../Extole/ExtoleBanner'
import { Route } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { fullstoryIdentify } from '../../../utils/fullstoryUtils'

const useStyles = makeStyles((theme) => ({
    snackBarAlert: {
        width: '100%',
        position: 'relative',
        top: '25vh',
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
}))

function ElevationScroll(props) {
    const { children } = props
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    })

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    })
}

const Header = (props) => {
    const baseUrl = window.React.base_url
    const brandLogoUrl = window.React.brandLogoUrl

    const theme = useTheme()
    const classes = useStyles(theme)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    })
    const [darkenViewPort, setDarkenViewPort] = useState(false)
    const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false)
    const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false)

    const { data: userSessionData, isLoading: loadingUserSessionData } =
        useLoggedIn()
    const isLoggedIn = Boolean(userSessionData && userSessionData.is_logged_in)
    const firstName = userSessionData && userSessionData.first_name

    const extoleUserData = useMemo(() => {
        if (!loadingUserSessionData && isLoggedIn) {
            return {
                email: userSessionData.username,
                first_name: userSessionData.first_name,
                last_name: userSessionData.last_name,
                phone_number: userSessionData.phone_number,
                partner_user_id: userSessionData.user_id,
            }
        }
        return {}
    }, [loadingUserSessionData, isLoggedIn, userSessionData])
    const [isSentExtoleRegistrationEvent, setIsSentExtoleRegistrationEvent] =
        useState(false)

    const disablePromotionBanner = window.React.disablePromotionBanner || 0

    const flashMessageResponse = useMemo(() => {
        if (userSessionData && userSessionData.flash_messages) {
            return userSessionData.flash_messages
        }
        return {}
    }, [userSessionData])
    const [flashMessageError, setFlashMessageError] = useState('')
    const [flashMessageSuccess, setFlashMessageSuccess] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpenSnackbar(false)
    }

    useEffect(() => {
        if (userSessionData) {
            fullstoryIdentify(userSessionData)
        }
    }, [userSessionData])

    useEffect(() => {
        if (
            !isSentExtoleRegistrationEvent &&
            !isEmpty(extoleUserData) &&
            typeof extole !== 'undefined'
        ) {
            extole.createZone({
                name: 'registration',
                data: extoleUserData,
            })
            setIsSentExtoleRegistrationEvent(true)
        }
    }, [
        isSentExtoleRegistrationEvent,
        setIsSentExtoleRegistrationEvent,
        extoleUserData,
    ])

    useEffect(() => {
        setDarkenViewPort(isDropdownMenuOpen || isSearchDropdownOpen)
    }, [isDropdownMenuOpen, isSearchDropdownOpen, setDarkenViewPort])

    useEffect(() => {
        if (
            isObject(flashMessageResponse) &&
            Object.keys(flashMessageResponse).length > 0
        ) {
            let tempFlashMessageError = ''
            let tempFlashMessageSuccess = ''

            for (const flashMessageLevel in flashMessageResponse) {
                flashMessageResponse[flashMessageLevel].forEach(function (
                    message
                ) {
                    // Set all symfony errors levels to same error message alert
                    if (
                        ['error', 'warning', 'danger'].indexOf(
                            flashMessageLevel
                        ) >= 0
                    ) {
                        tempFlashMessageError += ' ' + message
                    } else {
                        tempFlashMessageSuccess += ' ' + message
                    }
                })
            }
            setFlashMessageError(tempFlashMessageError)
            setFlashMessageSuccess(tempFlashMessageSuccess)
        }
    }, [flashMessageResponse])

    useEffect(() => {
        if (flashMessageSuccess.length > 0 || flashMessageError.length > 0) {
            setOpenSnackbar(true)
        }
    }, [flashMessageError, flashMessageSuccess])

    return (
        <>
            <ElevationScroll {...props}>
                {isMobile ? (
                    <MobileMenu
                        baseUrl={baseUrl}
                        logoImage={brandLogoUrl}
                        setIsSearchDropdownOpen={setIsSearchDropdownOpen}
                        isLoggedIn={isLoggedIn}
                        firstName={firstName}
                        disablePromotionBanner={disablePromotionBanner}
                        extoleUserData={extoleUserData}
                    />
                ) : (
                    <DesktopMenu
                        baseUrl={baseUrl}
                        logoImage={brandLogoUrl}
                        setIsDropdownMenuOpen={setIsDropdownMenuOpen}
                        setIsSearchDropdownOpen={setIsSearchDropdownOpen}
                        isLoggedIn={isLoggedIn}
                        firstName={firstName}
                        disablePromotionBanner={disablePromotionBanner}
                        extoleUserData={extoleUserData}
                    />
                )}
            </ElevationScroll>
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {/* only show one type of message as probability of different flash messages being set is low */}
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={flashMessageSuccess ? 'success' : 'error'}
                    className={classes.snackBarAlert}
                >
                    {flashMessageSuccess
                        ? flashMessageSuccess
                        : flashMessageError}
                </Alert>
            </Snackbar>
            <DarkenedViewport darkenViewPort={darkenViewPort} />
            <Route
                exact
                path={[
                    '/activate/',
                    '/check-balance/',
                    '/cart/order/:orderNumber/invoice/',
                ]}
                component={ExtoleBanner}
            />
        </>
    )
}
export default memo(Header)
