import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Dialog from '@material-ui/core/Dialog'
import { styled } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useState, useEffect } from 'react'
import DialogContent from '@material-ui/core/DialogContent'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: '#D9D9D9',
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#265c58',
        },
    },
    container: {
        borderRadius: '2rem',
        border: '0.3rem solid #7048b3',
    },
    contentContainer: {
        padding: '0 20px',
        marginBottom: '0',
        textAlign: 'center',
        '& p': {
            fontSize: 'large',
            color: '#542064',
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
                fontSize: 'xx-large',
            },
        },
    },
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: '1rem',
}))

const ProcessingModal = ({ modalIsOpen, message }) => {
    const classes = useStyles()
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    return 0
                }
                const remaining = 100 - oldProgress
                let diff = 5
                if (oldProgress > 50) {
                    diff = remaining / 15
                }
                if (remaining < 20) {
                    diff = 0.5
                }
                if (remaining < 10) {
                    diff = 0.25
                }
                return Math.min(oldProgress + diff, 100)
            })
        }, 500)

        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <Dialog
            open={modalIsOpen}
            classes={{
                paper: classes.container,
            }}
        >
            <DialogContent
                style={{
                    paddingBottom: '3rem',
                }}
            >
                <div className={classes.contentContainer}>
                    <p>{message}</p>
                </div>
                <div className={classes.root}>
                    <BorderLinearProgress
                        variant="determinate"
                        value={progress}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ProcessingModal
