import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CHECKOUT_STEP_LOGIN, CHECKOUT_STEP_PAYMENT } from '../Layout/Header'

const useStyles = makeStyles((theme) => ({
    breadcrumbItem: {
        textAlign: 'center',
        margin: '0 3em',
        display: 'inline-block',
        color: 'rgb(0,92,89)',
        position: 'relative',
        [theme.breakpoints.up('lg')]: {
            margin: '0 5em',
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '1em',
            left: '-8em',
            width: '9em',
            height: '0.1em',
            background: '#E2E2E2',
            zIndex: '-1',
            [theme.breakpoints.up('lg')]: {
                width: '12em',
                left: '-11em',
            },
        },
        '&:first-child::before': {
            display: 'none',
        },
        '& span': {
            display: 'block',
            width: '2em',
            height: '2em',
            lineHeight: '2em',
            borderRadius: '1em',
            color: 'white',
            background: 'rgb(0,92,89)',
            margin: '0px auto',
        },
    },
    breadcrumbItemActive: {
        '& span': {
            background: 'rgb(0,92,89)',
        },
        '& ~ li': {
            '& span': {
                background: '#fff',
                borderColor: '#e2e2e2',
                borderWidth: '1px',
                borderStyle: 'solid',
                color: '#797979',
            },
            '& div': {
                color: '#797979',
            },
        },
        '& ~ li::before': {
            background: '#e2e2e2',
        },
    },
}))

const CheckoutBreadcrumbs = ({ currentStep }) => {
    const classes = useStyles()

    return (
        <ul>
            <li
                id="checkout-breadcrumb-login"
                className={`${classes.breadcrumbItem} ${
                    currentStep == CHECKOUT_STEP_LOGIN
                        ? classes.breadcrumbItemActive
                        : ''
                }`}
            >
                <span>1</span>
                <div>Login</div>
            </li>
            <li
                id="checkout-breadcrumb-payment"
                className={`${classes.breadcrumbItem} ${
                    currentStep == CHECKOUT_STEP_PAYMENT
                        ? classes.breadcrumbItemActive
                        : ''
                }`}
            >
                <span>2</span>
                <div>Payment</div>
            </li>
            <li
                id="checkout-breadcrumb-review"
                className={classes.breadcrumbItem}
            >
                <span>3</span>
                <div>Review</div>
            </li>
        </ul>
    )
}

export default CheckoutBreadcrumbs
