import React from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { CLOUDINARY_NAME } from '../../constants'
import makeStyles from '@material-ui/core/styles/makeStyles'
import isEmpty from 'lodash/isEmpty'

const useStyles = makeStyles((theme) => ({
    image: {
        borderRadius: '10px',
        transition: (props) => props.transition,
        '&:hover': {
            transform: (props) => props.transform,
        },
    },
    bordered: {
        border: '1px solid #D1D1D1',
    },
}))

const MerchantCard = (props) => {
    const {
        title,
        cloudinaryId,
        imageSrc,
        bordered,
        width,
        height,
        additionalStyles,
        lazyLoad,
        scaleOnHover,
    } = props

    const styleProps = {
        transform: scaleOnHover ? 'scale(1.1)' : 'scale(1)',
        transition: scaleOnHover ? '.2s ease' : 'none',
    }
    const classes = useStyles(styleProps)

    const imageWidth = Math.round(width ?? 237)
    const imageHeight = Math.round(height ?? 150)

    return !isEmpty(cloudinaryId) ? (
        <Image
            data-testid="card-image"
            cloudName={CLOUDINARY_NAME}
            publicId={cloudinaryId}
            title={title}
            alt={title}
            itemProp="image"
            loading={lazyLoad ? 'lazy' : 'eager'}
            className={
                classes.image +
                (bordered ? ' ' + classes.bordered : '') +
                (additionalStyles ? ' ' + additionalStyles : '')
            }
            width={imageWidth}
            height={imageHeight}
        >
            <Transformation
                width={imageWidth}
                crop="scale"
                fetchFormat="auto"
            />
        </Image>
    ) : (
        <img
            data-testid="card-image"
            src={imageSrc}
            alt={title}
            title={title}
            itemProp="image"
            className={`${classes.image} ${bordered ? classes.bordered : ''} ${
                additionalStyles ? additionalStyles : ''
            }`}
            width={imageWidth}
            height={imageHeight}
            loading={lazyLoad ? 'lazy' : 'eager'}
        />
    )
}

export default MerchantCard
