import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { useSessionStorage } from 'web-api-hooks'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createACardTheme } from '../components/CreateACard/themeStyles'
import AppHelmet from '../components/CreateACard/Layout/AppHelmet'
import Layout from '../components/CreateACard/Layout/Layout'

import {
    addCustomCards,
    addToCartRequest,
    clearResetCacAppRequest,
    duplicateCustomCardRequest,
    editCustomCard,
    presetCustomCard,
    requestNextStep,
    resetAddressGroupsCardsAndBulkDeliveryGroups,
    resetCacApp,
    resetCustomCard,
    resetEditCustomCardRequest,
    setAddingToCart,
    setCacAvailableCardNetworks,
    setCacConfig,
    setCartErrors,
    setNextCard,
    clearDiscardItemAndReturnToReviewRequested,
} from '../actions/createACard'
import { pushBuildACardAddGiftToCartToDataLayer } from '../utils/dataLayer'
import createACardAddToCart from '../components/CreateACard/createACardAddToCart'
import addToCartErrorHandler from '../components/CreateACard/addToCartErrorHandler'
import cloneDeep from 'lodash/cloneDeep'
import { CARD_NETWORK } from '../constants'

export const STEP_LANDING = {
    name: 'landing',
    route: '/build-a-card/',
    pageTitle: 'Personalized Gift Cards - Custom Gift Cards',
    pageDescription:
        'Build a personalized gift card with a custom photo and message. Choose Visa or Mastercard and order custom gift cards easily with Gift Card Granny.',
}
export const STEP_CARRIER = {
    name: 'carrier',
    route: '/build-a-card/carrier/',
    pageTitle: {
        plastic: 'Carrier Personalization Options',
        virtual: 'Email Personalization Options',
    },
    pageDescription:
        'Personalize your customized Visa or Mastercard gift card from Gift Card Granny.',
}
export const STEP_REVIEW = {
    name: 'review',
    route: '/build-a-card/review/',
    pageTitle: 'Review your Build-a-Cards',
    pageDescription:
        'Review your custom Visa or Mastercard gift card(s) and carrier(s) and add them to your Gift Card Granny cart.',
}
export const steps = [STEP_LANDING, STEP_CARRIER, STEP_REVIEW]

export const CARRIER_STEP = {
    CARD_LANDING: 0,
    GREETING_CARD_DESIGN_SELECTION: 1,
    GREETING_CARD_PERSONALIZATION_INFO: 2,
    VIRTUAL_PERSONALIZATION_INFO: 3,
    DELIVERY_INFO: 4,
    REVIEW_ORDER: 5,
    CARRIER_COMPLETE: 6,
}

export const MIN_LOAD_VALUE = 10
export const MAX_LOAD_VALUE = 500
export const CLOUDINARY_NAME = 'gift-card-granny'

export const MASTERCARD_OVERLAY_ID =
    'GCG:card_overlays:Mastercard_GCG_Number_jghh12'
export const VISA_OVERLAY_ID = 'GCG:card_overlays:Visa_GCG_Number_z4ezjh'

export const getNetworkOverlayImageId = (cardNetworkName) => {
    return cardNetworkName.toLocaleLowerCase() === 'visa'
        ? VISA_OVERLAY_ID
        : MASTERCARD_OVERLAY_ID
}
const BAC_PAGE_METADATA = {
    VISA: {
        PAGE_TITLE: 'Buy Customized Visa Gift Cards Online | GiftCardGranny',
        PAGE_DESCRIPTION:
            'Create a personalized Visa gift card at GiftCardGranny! Upload your own photo, add a custom message, and make gifting unique. Order your custom gift card today!',
    },
    MASTERCARD: {
        PAGE_TITLE:
            'Buy Customized Mastercard Gift Cards Online | GiftCardGranny',
        PAGE_DESCRIPTION:
            'Create a personalized Mastercard gift card at GiftCardGranny! Upload your photo, add a custom message, and make your gift unique. Order your custom card today!',
    },
}
const CreateACard = (props) => {
    const {
        children,
        buildACardConfig,
        baseUrl,
        brandLogoUrl,
        cardNetworks,
        faqUrl,
        addToCartUrl,
        addToCartCallback,
        footerContent,
        carrierImageUrl,
        carrierImageWithCardUrl,
        includeShipping,
        imageGuidelinesUrl,
        siteName,
        availableShippingMethods,
        addressVerificationEnabled,
    } = props

    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const addToCart = createACardAddToCart

    const {
        bulkDeliveryGroups,
        cards,
        activeCard,
        customCards,
        isEditing,
        nextStepRequested,
        resetAppRequested,
        clearCards,
        addToCartRequested,
        editCardRequest,
        duplicateCardId,
        availableCardNetworks,
        defaultCardTypeIsVirtual,
        discardItemAndReturnToReviewRequested,
    } = useSelector((state) => state.createACard)

    // What step in the flow the user is on.
    const [step, setStep] = useSessionStorage('step', STEP_LANDING)
    const getStepByRoute = (route) => {
        if (steps.find((el) => el.route === route))
            return steps.find((el) => el.route === route)
        return STEP_LANDING
    }
    const getBacMetaDescription = () => {
        if (isEqual(step, STEP_LANDING)) {
            if (buildACardConfig?.slug) {
                return null // let fullScreenApp.html.twig handle
            }

            /**
             * We didn't use to have Visa/MC specific BAC landing page routes (used to just be /build-a-card/).
             * The page title/description used to be controlled by updating the BAC config in admin
             * that didn't have a slug. Now, we want each network-specific landing page route to have a
             * unique page title/description. This is ripe for refactoring and cleaning up holiday rush tech debt.
             */
            return window.location.pathname.includes(CARD_NETWORK.VISA)
                ? BAC_PAGE_METADATA.VISA.PAGE_DESCRIPTION
                : BAC_PAGE_METADATA.MASTERCARD.PAGE_DESCRIPTION
        }

        return step.pageDescription
    }

    const getBacCanonical = () => {
        const path = location?.pathname?.trim()

        if (
            path === '/visa-gift-cards/buildacard' ||
            path === '/visa-gift-cards/buildacard/'
        ) {
            return `${baseUrl}/visa-gift-cards`
        } else if (
            path === '/mastercard-gift-cards/buildacard' ||
            path === '/mastercard-gift-cards/buildacard/'
        ) {
            return `${baseUrl}/mastercard-gift-cards`
        } else {
            return null
        }
    }

    const stepByRoute = getStepByRoute(location.pathname)

    const pageTitle = useMemo(() => {
        if (isEqual(stepByRoute, STEP_LANDING)) {
            if (buildACardConfig?.slug) {
                return null // let fullScreenApp.html.twig handle
            }

            /**
             * We didn't use to have Visa/MC specific BAC landing page routes (used to just be /build-a-card/).
             * The page title/description used to be controlled by updating the BAC config in admin
             * that didn't have a slug. Now, we want each network-specific landing page route to have a
             * unique page title/description. This is ripe for refactoring and cleaning up holiday rush tech debt.
             */
            return window.location.pathname.includes(CARD_NETWORK.VISA)
                ? BAC_PAGE_METADATA.VISA.PAGE_TITLE
                : BAC_PAGE_METADATA.MASTERCARD.PAGE_TITLE
        }

        if (isEqual(stepByRoute, STEP_CARRIER)) {
            const isVirtual =
                activeCard !== null
                    ? activeCard.isVirtual()
                    : defaultCardTypeIsVirtual
            return isVirtual
                ? STEP_CARRIER.pageTitle.virtual
                : STEP_CARRIER.pageTitle.plastic
        } else {
            return stepByRoute.pageTitle
        }
    }, [
        stepByRoute,
        buildACardConfig.slug,
        activeCard,
        defaultCardTypeIsVirtual,
    ])

    const noCardsAreComplete = useCallback(() => {
        return customCards.every((card) => !card.isComplete())
        // JSON.stringify(customCards) to fix infinite re-render issue because customCards: [] set in reducer action
        // eslint-disable-next-line
    }, [JSON.stringify(customCards)])

    const allCardsAreComplete = useCallback(() => {
        return customCards.every((card) => card.isComplete())
        // JSON.stringify(customCards) to fix infinite re-render issue because customCards: [] set in reducer action
        // eslint-disable-next-line
    }, [JSON.stringify(customCards)])

    useEffect(() => {
        if (isEqual(step, STEP_LANDING) && isEqual(stepByRoute, STEP_CARRIER)) {
            previousStep()
            return
        }

        // User navigated manually navigated from carrier step to landing step.
        // If no cards are complete, reset the app, otherwise the quantity
        // selector will be hidden.
        if (isEqual(step, STEP_CARRIER) && isEqual(stepByRoute, STEP_LANDING)) {
            if (noCardsAreComplete()) {
                setStep(STEP_LANDING)
                dispatch(resetCustomCard())
            }
        }

        if (stepByRoute !== step) {
            if (
                steps.findIndex((i) => i === stepByRoute) <=
                steps.findIndex((i) => i === step)
            ) {
                setStep(stepByRoute)
            }
        }
        // eslint-disable-next-line
    }, [location.pathname, noCardsAreComplete, dispatch])

    useEffect(() => {
        dispatch(
            setCacConfig({
                baseConfig: buildACardConfig,
                baseUrl: baseUrl,
                siteName: siteName,
                faqUrl: faqUrl,
                carrierImageUrl: carrierImageUrl,
                carrierImageWithCardUrl: carrierImageWithCardUrl,
                imageGuidelinesUrl: imageGuidelinesUrl,
                includeShipping: includeShipping,
                availableShippingMethods: availableShippingMethods,
                addressVerificationEnabled: addressVerificationEnabled,
            })
        )
    }, [
        dispatch,
        buildACardConfig,
        baseUrl,
        siteName,
        faqUrl,
        carrierImageUrl,
        carrierImageWithCardUrl,
        imageGuidelinesUrl,
        includeShipping,
        availableShippingMethods,
        addressVerificationEnabled,
    ])

    useEffect(() => {
        if (
            isEmpty(availableCardNetworks) ||
            !isEqual(availableCardNetworks, cardNetworks)
        ) {
            dispatch(setCacAvailableCardNetworks(cardNetworks))
        }
    }, [availableCardNetworks, dispatch, cardNetworks])

    const resetApp = useCallback(
        (resetCards, occasionSlug) => {
            // Reset card builder properties
            setStep(STEP_LANDING)

            if (resetCards === true) {
                // Remove saved cards
                dispatch(resetCacApp())
            } else {
                dispatch(resetCustomCard())
            }

            // Send back to landing page
            occasionSlug == null
                ? history.push(STEP_LANDING.route)
                : history.push(`${STEP_LANDING.route}${occasionSlug}/`)
        },
        [history, dispatch, setStep]
    )

    const resetForm = useCallback(() => {
        dispatch(resetCustomCard())
    }, [dispatch])

    const resetAddressGroupsCardListAndBulkDeliveryGroups = useCallback(() => {
        dispatch(resetAddressGroupsCardsAndBulkDeliveryGroups())
    }, [dispatch])

    const findCard = useCallback(
        (cardId) => {
            return cards.find((card) => card.cardId === cardId)
        },
        [cards]
    )

    const editCard = useCallback(
        (cardId, step) => {
            const card = findCard(cardId)
            // Set the step we are on
            setStep(step)
            // Set card builder properties to selected card
            dispatch(presetCustomCard(card))
            // Send to landing page
            history.push(step.route)
        },
        [dispatch, setStep, history, findCard]
    )

    const getNextCard = useCallback(() => {
        for (let i = 0; i < customCards.length; i++) {
            if (!customCards[i].isComplete()) {
                return customCards[i]
            }
        }
        return customCards[customCards.length - 1]
        // JSON.stringify(customCards) to fix infinite re-render issue because customCards: [] set in reducer action
        // eslint-disable-next-line
    }, [JSON.stringify(customCards)])

    const getNextStep = useCallback(() => {
        if (activeCard.isComplete()) {
            if (allCardsAreComplete()) {
                return STEP_REVIEW
            } else {
                const nextCard = getNextCard()
                return nextCard.getStep()
            }
        }
        return activeCard.getStep()
    }, [activeCard, allCardsAreComplete, getNextCard])

    const getPreviousStep = () => steps[steps.findIndex((i) => i === step) - 1]

    const stringifiedCustomCards = JSON.stringify(customCards)

    const nextStep = useCallback(() => {
        if (!step) setStep(STEP_LANDING)
        const nextStep = getNextStep()

        if (!nextStep) return

        if (nextStep === STEP_REVIEW) {
            if (isEditing) {
                dispatch(editCustomCard(activeCard))
            } else {
                customCards.forEach((card) => {
                    pushBuildACardAddGiftToCartToDataLayer(
                        card.cardNetwork.name,
                        card.amount,
                        1
                    )
                })
                dispatch(addCustomCards(customCards))
            }
        } else {
            if (activeCard.isComplete()) {
                const nextCard = getNextCard()
                dispatch(setNextCard({ nextCard: nextCard }))
            }
        }

        setStep(nextStep)
        history.push(nextStep.route)
        // JSON.stringify(customCards) to fix infinite re-render issue because customCards: [] set in reducer action
        // eslint-disable-next-line
    }, [
        stringifiedCustomCards,
        step,
        setStep,
        getNextStep,
        dispatch,
        activeCard,
        getNextCard,
        history,
        isEditing,
    ])

    const previousStep = () => {
        // Previous will always be valid (or not exist)
        let previousStep = getPreviousStep()

        if (!previousStep) {
            previousStep = STEP_LANDING
        }

        setStep(previousStep)
        history.push(previousStep.route)
    }

    const handleSetCartErrors = useCallback(
        (errors) => {
            dispatch(setCartErrors(errors))
        },
        [dispatch]
    )
    const handleCartError = useCallback(
        (error) => {
            addToCartErrorHandler(error, handleSetCartErrors)
        },
        [handleSetCartErrors]
    )

    const handleSetAddingToCart = useCallback(
        (addingToCart) => {
            dispatch(setAddingToCart(addingToCart))
        },
        [dispatch]
    )

    useEffect(() => {
        setCartErrors([])
        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        if (nextStepRequested) {
            nextStep()
            dispatch(requestNextStep(false))
        }
    }, [nextStepRequested, dispatch, nextStep])

    useEffect(() => {
        if (resetAppRequested) {
            dispatch(clearResetCacAppRequest())
            resetApp(clearCards, buildACardConfig.slug)
        }
    }, [
        resetAppRequested,
        dispatch,
        clearCards,
        resetApp,
        buildACardConfig.slug,
    ])

    useEffect(() => {
        if (discardItemAndReturnToReviewRequested) {
            dispatch(clearDiscardItemAndReturnToReviewRequested())
            setStep(STEP_REVIEW)
            history.push(STEP_REVIEW.route)
        }
    }, [discardItemAndReturnToReviewRequested, dispatch, history, setStep])

    useEffect(() => {
        async function sendAddToCartRequest(formData) {
            return await axios.post(addToCartUrl, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        }

        const handleAddToCart = () => {
            handleSetCartErrors([])
            addToCart(
                bulkDeliveryGroups,
                cards,
                handleSetAddingToCart,
                resetForm,
                resetAddressGroupsCardListAndBulkDeliveryGroups,
                sendAddToCartRequest,
                addToCartCallback,
                handleCartError
            )
        }

        if (addToCartRequested) {
            handleAddToCart()
            dispatch(addToCartRequest(false))
        }
    }, [
        addToCartRequested,
        dispatch,
        bulkDeliveryGroups,
        cards,
        addToCart,
        handleSetAddingToCart,
        resetForm,
        resetAddressGroupsCardListAndBulkDeliveryGroups,
        addToCartCallback,
        addToCartUrl,
        handleCartError,
        handleSetCartErrors,
    ])

    useEffect(() => {
        if (!isEmpty(editCardRequest)) {
            editCard(editCardRequest.cardId, editCardRequest.step)
            dispatch(resetEditCustomCardRequest())
        }
    }, [editCardRequest, dispatch, editCard])

    useEffect(() => {
        const duplicateCard = (cardId) => {
            const cardToDuplicate = findCard(cardId)
            if (cardToDuplicate) {
                const card = cloneDeep(cardToDuplicate)
                card.setNewCardId()
                card.deliveryGroupUuid = null
                card.groupShipment = null
                card.addressGroupId = null
                dispatch(addCustomCards([card]))
            }
        }

        if (duplicateCardId) {
            duplicateCard(duplicateCardId)
            dispatch(duplicateCustomCardRequest(null))
        }
    }, [duplicateCardId, dispatch, findCard])

    return !isEmpty(buildACardConfig) ? (
        <MuiThemeProvider theme={createACardTheme}>
            <AppHelmet
                pageTitle={pageTitle}
                pageDescription={getBacMetaDescription()}
                pageCanonical={getBacCanonical()}
            />
            <CssBaseline />
            <Layout
                baseUrl={baseUrl}
                faqUrl={faqUrl}
                brandLogoUrl={brandLogoUrl}
                footerContent={footerContent}
                useCreateACardFooter={true}
                removeFooterMargin={!isEqual(step, STEP_REVIEW)}
            >
                {children}
            </Layout>
        </MuiThemeProvider>
    ) : null
}

export default CreateACard
