export const SMB_DEFAULT_SYNC_ERROR_MESSAGE =
    'There was an error processing your request. Please refresh and try again.'
export const SMB_ERROR_ADDING_TO_CART =
    'SMB - Error adding delivery group to SMB cart'
export const SMB_ERROR_REMOVING_FROM_CART =
    'SMB - Error removing delivery group from SMB cart'
export const SMB_ERROR_UPDATING_DELIVERY_GROUP_SHIPPING_INFORMATION =
    'SMB - Error updating delivery group shipping information'
export const SMB_ERROR_UPDATING_DELIVERY_GROUP_SHIPPING_INFORMATION_MESSAGE =
    "There was an error updating one of your delivery group's shipping information. Please refresh and try again."
export const SMB_ERROR_EDITING_CART =
    'SMB - Error editing delivery group in cart'
export const SMB_ERROR_CREATING_PAYMENT_INTENT =
    'SMB - Error creating payment intent'
export const SMB_ERROR_UPDATING_PAYMENT_METHOD =
    "SMB - Error updating user's payment method"
export const SMB_ERROR_SUBMITTING_COBRAND_FOR_APPROVAL =
    'SMB - Error submitting cobrand for approval'
export const SMB_ERROR_AGREE_RIGHTS = 'Please acknowledge you have rights to use all logos, any images, and/or text'
export const SMB_ERROR_CREATING_ORDER = 'SMB - Error creating order'
export const ERROR_CREATING_PAYMENT_INTENT = 'Error creating payment intent'
export const ERROR_CANCELLING_PAYMENT_INTENT = 'Error cancelling payment intent'
export const ERROR_UPDATING_PAYMENT_METHOD =
    "Error updating user's payment method"
export const ERROR_DELETING_PAYMENT_METHOD =
    "Error deleting user's payment method"
export const ERROR_GETTING_USER_PAYMENT_METHOD_INFO =
    "Error getting the user and/or the user's payment methods"
export const ERROR_GETTING_USER_PAYMENT_METHODS =
    'Error getting user payment methods'
export const ERROR_GETTING_CART = 'Error getting cart'
export const ERROR_GETTING_FEATURED_MERCHANTS =
    'Error getting featured merchants'
export const ERROR_UPDATING_CART = 'Error updating cart'
export const ERROR_SIGNING_UP_GUEST_USER = 'Error signing up guest user'
export const ERROR_CREATING_PAYPAL_ORDER = 'Error creating PayPal order'
export const ERROR_AUTHORIZING_PAYPAL_PAYMENT =
    'Error authorizing PayPal payment'
export const ERROR_GETTING_OPEN_LOOP_PRODUCTS =
    'Error getting open loop products'
export const ERROR_GETTING_OPEN_LOOP_PRODUCT_CATEGORIES =
    'Error getting open loop product categories'
export const ERROR_FILTERING_BAD_WORDS = 'Error filtering bad words'
export const ERROR_VALIDATING_ADDRESS = 'Error validating address'
export const ERROR_CHECK_PHONE_DELIVERABILITY = 'Error checking phone deliverability'
export const ERROR_ADDING_CARDS_TO_CART = 'Error adding cards to cart'
export const ERROR_GETTING_OCCASIONS = 'Error getting occasions'
export const ERROR_GETTING_GIFT_TEMPLATES = 'Error getting gift templates'
export const ERROR_GETTING_GIFT_TEMPLATE_CATEGORIES =
    'Error getting gift template categories'
export const ERROR_GETTING_DESIGN_CATEGORIES = 'Error getting design categories'
export const ERROR_GETTING_GREETING_CARDS = 'Error getting greeting cards'
export const ERROR_ACTIVATING_CARD = 'Error activating card'
export const ERROR_ACTIVATING_CARD_NOT_FOUND =
    'Error activating card, not found'
export const ERROR_CHECKING_BALANCE = 'Error checking balance for card'
export const ERROR_CHECKING_BALANCE_CARD_NOT_FOUND =
    'Error checking balance for card, not found'
export const ERROR_LOGGING_PAYMENT_FAILURE = 'Error logging payment failure'
export const ERROR_GETTING_AVAILABLE_FEDEX_SHIPPING_METHODS_FOR_ADDRESS =
    'Error getting available FedEx shipping methods for an address'
export const ERROR_GETTING_FAILED_EMAIL_RECIPIENT =
    'Error getting failed email recipient to update'
export const ERROR_UPDATING_FAILED_EMAIL_RECIPIENT =
    'Error updating failed email recipient'
export const ERROR_SIGNING_UP_FOR_NEWSLETTER = 'Error signing up for newsletter'
export const ERROR_GETTING_MENU_DATA = 'Error getting menu data'
export const ERROR_GETTING_LOGGED_IN = 'Error getting logged in status'
export const ERROR_POSTING_GIFT_REDEMPTION = 'Error posting gift redemption'
export const ERROR_FETCHING_GIFT = 'Error fetching gift'
export const ERROR_CHECKING_VIRTUAL_EXCHANGE_STATUS =
    'Error when checking virtual exchange status'
export const ERROR_SUBMITTING_VIRTUAL_EXCHANGE =
    'Error submitting virtual exchange'
export const ERROR_GETTING_FAVORITE_DATA = 'Error getting favorite data'
export const ERROR_UPDATING_FAVORITE_DATA = 'Error updating favorite data'
export const ERROR_IDOLOGY_CHECK_USER =
    'Error checking user for needed IDology validation'
export const ERROR_IDOLOGY_VALIDATE_CUSTOMER =
    'Error validating customer via IDology'
export const ERROR_IDOLOGY_SEND_ANSWERS = 'Error sending answers to IDology'
export const ERROR_GET_ORDER = 'Error getting order by order number'
export const ERROR_POST_REFUND_REQUEST = 'Error posting refund request'
export const ERROR_CANCEL_ORDER = 'Error cancelling order'
export const ERROR_GETTING_USER_COBRANDS = 'Error getting user cobrands'
export const ERROR_SUBMITTING_COBRAND = 'Error submitting user cobrand'
export const ERROR_GETTING_BLOB_FROM_OBJECT_URL =
    'Error getting blob from object URL'
export const ERROR_CONVERTING_BLOB_TO_DATA_URL =
    'Error converting blob to data URL'
export const ERROR_UPLOADING_IMAGE_TO_CLOUDINARY =
    'Error uploading image to Cloudinary'
export const ERROR_REDIRECTING_TO_ORDER_CONFIRMATION_PAGE =
    'Error redirecting to order confirmation page'
export const ERROR_NO_ORDER_RETURNED =
    'Error - no order returned from order creation'
export const ERROR_LOG_PAYPAL_FAILURE_AUTHORIZATION =
    'Error logging paypal failure authorization'
export const ERROR_AUTHORIZING_FISERV_SESSION =
    'Error authorizing Fiserv session'

export const ERROR_FETCHING_COMMERCE_HUB_CREDENTIALS =
    'Error fetching commerce hub credentials'


export const ERROR_TOKENIZING_PAYMENT_COMMERCE_HUB =
    'Error tokenizing payment with commerce hub'
