import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import { pushToDataLayer } from '../../../../utils/dataLayer'
import { DropDownDivider } from '../../Icons/DropDownDivider'
import { CheckBalanceIcon } from '../../Icons/CheckBalanceIcon'
import { ActivateCardIcon } from '../../Icons/ActivateCardIcon'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    menuDropdownButton: {
        borderWidth: '2px',
        '&:hover': {
            borderWidth: '2px',
            textDecoration: 'underline',
        },
    },
    menuDropdownButtonText: {
        fontWeight: 'bold',
    },
    popoverLink: {
        color: '#222222',
        textUnderlineOffset: '1px',
        paddingLeft: '1em',
        paddingRight: '1em',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    popoverLinkText: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.95rem',
        },
    },
    popoverListItemText: {
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.95rem',
        },
        paddingLeft: '1em',
        paddingRight: '1em',
    },
}))

const GiftReceivedMenuItems = () => {
    const classes = useStyles()
    const { base_url } = window.React
    const MenuItemLink = ({ href, linkText, analyticsEvent }) => {
        return (
            <MenuItem
                style={{ marginLeft: linkText === 'Activate Card' ? 6 : 0 }}
            >
                <Link
                    href={href}
                    className={classes.popoverLink}
                    onClick={() => pushToDataLayer(analyticsEvent)}
                >
                    <Typography className={classes.popoverLinkText}>
                        {linkText}
                    </Typography>
                </Link>
            </MenuItem>
        )
    }

    return (
        <React.Fragment>
            <ActivateCardIcon />
            <MenuItemLink
                href={`${base_url}/activate/`}
                linkText={'Activate Card'}
                analyticsEvent={[
                    'HeaderClick',
                    'Header',
                    'Click',
                    'Activate Card',
                ]}
            />
            <DropDownDivider />
            <CheckBalanceIcon />
            <MenuItemLink
                href={`${base_url}/gift-card-balance-check/`}
                linkText={'Check Balance'}
                analyticsEvent={[
                    'HeaderClick',
                    'Header',
                    'Click',
                    'Check Balance',
                ]}
            />
        </React.Fragment>
    )
}

export default GiftReceivedMenuItems
