import React, { lazy, useState, useEffect, useRef } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import NortonLogo from './NortonLogo'
import IconButton from '@material-ui/core/IconButton'
import useLoggedIn from '../../hooks/useLoggedIn'
import { pushToDataLayer } from '../../utils/dataLayer'

const useStyles = makeStyles((theme) => ({
    siteFooter: {
        background: '#0A3A52',
        padding: '0 20px 30px',
        margin: '0 auto',
        borderTop: '10px solid #169E84',
        '& a': {
            display: 'block',
            color: 'white',
            padding: '3px 0',
            textDecoration: 'none',
            '&:hover': {
                color: '#1ED3B0',
            },
        },
    },
    siteFooterRow: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: 1280,
        margin: '0 auto',
        paddingTop: 20,
        paddingBottom: 10,
        '&:after': {
            display: 'none',
        },
        '&:last-of-type': {
            paddingBottom: 0,
        },
        [theme.breakpoints.up('md')]: {
            flexWrap: 'nowrap',
        },
    },
    siteFooterCol: {
        width: '100%',
        float: 'none',
        flexGrow: 1,
        fontSize: '1rem',
        marginRight: '1.25rem',
        [theme.breakpoints.up('sm')]: {
            width: '30%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '33.3333%',
        },
    },
    siteFooterTitle: {
        display: 'block',
        color: '#1ED3B0',
        marginBottom: '.9rem',
        marginTop: '1.7rem',
        fontSize: '1.13rem',
        fontWeight: 600,
    },
    connectTitle: {
        color: 'white',
    },
    footerLegalLinkContainer: {
        color: 'white',
        border: '0px solid #169E84',
        borderTopWidth: '1px',
        borderBottomWidth: '1px',
        maxWidth: 1280,
        padding: '20px',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            margin: '20px auto',
        },
    },
    copyright: {
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'inline',
        },
    },
    footerLegalLinks: {
        display: 'inline!important',
        margin: '0 5px!important',
    },
    siteFooterBadges: {
        display: 'flex',
        flexGrow: 0,
        margin: 'auto',
        width: 'auto',
        '& >div': {
            padding: '0 20px',
            '& a': {
                display: 'block',
            },
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '1rem',
        },
        '& #site-footer-digicert-container': {
            padding: '10px 0',
        },
    },
    visaFooterDisclaimer: {
        maxWidth: 1200,
        margin: '0 auto 1em',
        flexGrow: 1,
        fontSize: '0.9rem',
        color: '#CDCDCD',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.9rem',
        },
    },
    bbbImg: {
        border: 0,
        maxHeight: 63,
    },
    lazyLoaded: {
        opacity: 1,
        transition: 'opacity 300ms',
    },
    wolfeInline: {
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
    textUsPopUp: {
        background: 'white',
        color: 'black',
        padding: '10px',
        borderRadius: '5px',
        position: 'absolute',
        top: '5px',
        fontWeight: '600',
        width: '15rem',
        fontSize: '.9rem',
    },
    connectIconsContainer: {
        position: 'relative',
        display: 'flex',
        color: '#FFFFFF',
        '> a': {
            display: 'inherit',
        },
    },
    connectIconContainer: {
        margin: '10px 10px 20px 0',
        backgroundColor: '#7347B2',
        color: 'white',
        width: '45px',
        height: '45px',
        '&:after': {
            content: 'attr(data-label)',
            position: 'absolute',
            left: '52px',
            color: 'white',
            fontSize: '1rem',
            textAlign: 'left',
            lineHeight: '21px',
        },
        '&:hover': {
            backgroundColor: 'white',
            '> img': {
                filter: 'invert(33%) sepia(69%) saturate(610%) hue-rotate(223deg) brightness(88%) contrast(97%)',
            },
        },
        '> img': {
            filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)',
        },
    },
}))

const Footer = ({ baseUrl, faqUrl }) => {
    const classes = useStyles()
    const digicertTestClassEnabled = !!window.React.digicertTestClassEnabled

    const { data: userSessionData, isLoading: loadingUserSessionData } =
        useLoggedIn()

    const extoleUserData =
        !loadingUserSessionData &&
        userSessionData &&
        userSessionData.is_logged_in
            ? {
                  email: userSessionData.username,
                  first_name: userSessionData.first_name,
                  last_name: userSessionData.last_name,
                  phone_number: userSessionData.phone_number,
                  partner_user_id: userSessionData.user_id,
              }
            : null

    useEffect(() => {
        /** Do feature detection, load polyfill if it is needed. */
        if (typeof window.IntersectionObserver === 'undefined') {
            import('intersection-observer')
        }
    }, [])

    return (
        <>
            <footer className={`${classes.siteFooter}`}>
                <Grid container className={classes.siteFooterRow}>
                    <Grid item sm={12} md={4} className={classes.siteFooterCol}>
                        <span className={classes.siteFooterTitle}>About</span>
                        <a
                            href={`${baseUrl}/about/`}
                            data-layer-push-event="FooterClick|Footer|Click|Our Story"
                        >
                            Our story
                        </a>
                        <a
                            href={`${baseUrl}/legit/`}
                            data-layer-push-event="FooterClick|Footer|Click|Is Gift Card Granny Legit?"
                        >
                            Is Gift Card Granny Legit?
                        </a>
                        <a
                            href={`${baseUrl}/affiliate/`}
                            data-layer-push-event="FooterClick|Footer|Click|Affiliate"
                        >
                            Affiliate Program
                        </a>
                        <a
                            href={`${baseUrl}/press/`}
                            data-layer-push-event="FooterClick|Footer|Click|Press & media"
                        >
                            Press & media
                        </a>
                        <a
                            href={`${baseUrl}/reviews/`}
                            data-layer-push-event="FooterClick|Footer|Click|Reviews"
                        >
                            Reviews
                        </a>
                        <a
                            href={`${baseUrl}/trae-bodge/`}
                            data-layer-push-event="FooterClick|Footer|Click|Trae Bodge spokesperson"
                        >
                            Our spokesperson, Trae Bodge
                        </a>
                        <a
                            href={`${baseUrl}/press/gift-card-research/`}
                            data-layer-push-event="FooterClick|Footer|Click|Research & Trends"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            Research & Trends
                        </a>
                        <a
                            href={`${baseUrl}/blog/`}
                            data-layer-push-event="FooterClick|Footer|Click|Money Saving Blog"
                        >
                            Blog
                        </a>
                        <a
                            href={`${baseUrl}/refer-a-friend/`}
                            data-layer-push-event="FooterClick|Footer|Click|Give $5 Get $5"
                        >
                            Give $5 Get $5
                        </a>
                    </Grid>
                    <Grid item sm={12} md={4} className={classes.siteFooterCol}>
                        <span className={classes.siteFooterTitle}>Shop</span>
                        <a
                            href={`${baseUrl}/visa-gift-cards/predesign/`}
                            data-layer-push-event="FooterClick|Footer|Click|Visa"
                        >
                            Visa Gift Cards
                        </a>
                        <a
                            href={`${baseUrl}/mastercard-gift-cards/predesign/`}
                            data-layer-push-event="FooterClick|Footer|Click|Mastercard"
                        >
                            Mastercard Gift Cards
                        </a>
                        <a
                            href={`${baseUrl}/buy-gift-cards/`}
                            data-layer-push-event="FooterClick|Footer|Click|National brands"
                        >
                            National Brands
                        </a>
                        <a
                            href={`${baseUrl}/buy-gift-cards/`}
                            data-layer-push-event="FooterClick|Footer|Click|Gift cards"
                        >
                            Gift Cards
                        </a>
                        <a
                            href={`${baseUrl}/e-gift-cards-discounts/`}
                            data-layer-push-event="FooterClick|Footer|Click|Discounts"
                        >
                            Discounts
                        </a>
                        <a
                            href="https://www.giftya.com"
                            data-layer-push-event="FooterClick|Footer|Click|GiftYa"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            GiftYa
                        </a>
                        <a
                            href={`${baseUrl}/bulk-gift-cards/`}
                            data-layer-push-event="FooterClick|Footer|Click|Corporate bulk"
                        >
                            Buy in bulk
                        </a>
                        <a
                            href={`${baseUrl}/free-gift-cards/`}
                            data-layer-push-event="FooterClick|Footer|Click|Earn rewards"
                        >
                            Earn rewards
                        </a>
                    </Grid>
                    <Grid item sm={12} md={4} className={classes.siteFooterCol}>
                        <span className={classes.siteFooterTitle}>Support</span>
                        <a
                            href={`${baseUrl}/activate/`}
                            data-layer-push-event="FooterClick|Footer|Click|Activate a card"
                        >
                            Activate a Visa or Mastercard
                        </a>
                        <a
                            href={`${baseUrl}/check-balance/`}
                            data-layer-push-event="FooterClick|Footer|Click|Check a gift card balance"
                        >
                            Check Balance on a Visa or Mastercard
                        </a>
                        <a
                            href={`${baseUrl}/orderstatus/`}
                            data-layer-push-event="FooterClick|Footer|Click|Track Order"
                        >
                            Track Order
                        </a>
                        <a
                            href={faqUrl}
                            data-layer-push-event="FooterClick|Footer|Click|FAQs"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            FAQ's
                        </a>
                        <a
                            href={faqUrl}
                            data-layer-push-event="FooterClick|Footer|Click|Contact us"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            Customer Service
                        </a>
                        <a
                            href={`${baseUrl}/sitemap/`}
                            data-layer-push-event="FooterClick|Footer|Click|Sitemap"
                        >
                            Sitemap
                        </a>
                    </Grid>
                    <Grid item sm={12} md={4} className={classes.siteFooterCol}>
                        <span className={classes.siteFooterTitle}>Connect</span>
                        <span className={classes.connectTitle}>Contact Us</span>
                        <div className={classes.connectIconsContainer}>
                            <IconButton
                                href="https://www.facebook.com/GiftCardGranny"
                                target="_blank"
                                rel="nofollow noopener"
                                aria-label="Facebook icon"
                                classes={{
                                    root: classes.connectIconContainer,
                                }}
                            >
                                <img
                                    alt="Facebook Icon"
                                    src={'/social-media/icons/Facebook.svg'}
                                    width="45"
                                    height="45"
                                    loading="lazy"
                                />
                            </IconButton>
                            <IconButton
                                href="https://twitter.com/giftcardgranny"
                                target="_blank"
                                rel="nofollow noopener"
                                aria-label="Twitter icon"
                                classes={{
                                    root: classes.connectIconContainer,
                                }}
                            >
                                <img
                                    alt="Twitter Icon"
                                    src={'/social-media/icons/Twitter.svg'}
                                    width="45"
                                    height="45"
                                    loading="lazy"
                                />
                            </IconButton>
                            <IconButton
                                href="https://www.linkedin.com/company/gift-card-granny-llc/"
                                target="_blank"
                                rel="nofollow noopener"
                                aria-label="LinkedIn icon"
                                classes={{
                                    root: classes.connectIconContainer,
                                }}
                            >
                                <img
                                    alt="LinkedIn Icon"
                                    src={'/social-media/icons/LinkedIn.svg'}
                                    width="45"
                                    height="45"
                                    loading="lazy"
                                />
                            </IconButton>
                            <IconButton
                                href="https://www.instagram.com/giftcard_granny/"
                                target="_blank"
                                rel="nofollow noopener"
                                aria-label="Instagram icon"
                                classes={{
                                    root: classes.connectIconContainer,
                                }}
                            >
                                <img
                                    alt="Instagram Icon"
                                    src={'/social-media/icons/Instagram.svg'}
                                    width="45"
                                    height="45"
                                    loading="lazy"
                                />
                            </IconButton>
                            <IconButton
                                href="https://www.youtube.com/channel/UChf3CH5jTxhr_c-R2rOVEXA/"
                                target="_blank"
                                rel="nofollow noopener"
                                aria-label="YouTube icon"
                                classes={{
                                    root: classes.connectIconContainer,
                                }}
                            >
                                <img
                                    alt="YouTube Icon"
                                    src={'/social-media/icons/YouTube.svg'}
                                    width="45"
                                    height="45"
                                    loading="lazy"
                                />
                            </IconButton>
                        </div>
                        <span className={classes.connectTitle}>
                            Download our app
                        </span>
                        <div className={classes.connectIconsContainer}>
                            <IconButton
                                href="https://play.google.com/store/apps/details?id=com.kinoli.giftcardgranny"
                                target="_blank"
                                data-app-store="Google Play"
                                rel="noreferrer noopener"
                                data-layer-push-event="FooterClick|Footer|Click|Google Play Store"
                                aria-label="Google Play icon"
                                data-label="Google Play"
                                style={{ marginRight: '80px' }}
                                classes={{
                                    root: classes.connectIconContainer,
                                }}
                            >
                                <img
                                    alt="Google Play Icon"
                                    src={'/social-media/icons/google_play.svg'}
                                    width="35"
                                    height="35"
                                    loading="lazy"
                                />
                            </IconButton>
                            <IconButton
                                href="https://apps.apple.com/app/apple-store/id703155858"
                                target="_blank"
                                data-app-store="iTunes"
                                rel="noreferrer noopener"
                                data-layer-push-event="FooterClick|Footer|Click|Apple App Store"
                                aria-label="App Store icon"
                                data-label="App Store"
                                classes={{
                                    root: classes.connectIconContainer,
                                }}
                            >
                                <img
                                    alt="Apple Icon"
                                    src={'/social-media/icons/apple.svg'}
                                    width="40"
                                    height="40"
                                    loading="lazy"
                                />
                            </IconButton>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.footerLegalLinkContainer}>
                    <div className={classes.copyright}>
                        <span>
                            &copy; {new Date().getFullYear()} Gift Card Granny
                            LLC -{' '}
                        </span>
                    </div>
                    <a
                        className={classes.footerLegalLinks}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.wolfe.com/`}
                        data-layer-push-event="FooterClick|Footer|Click|Wolfe"
                    >
                        Part of{' '}
                        <span className={classes.wolfeInline}>
                            The Wolfe Companies, LLC
                        </span>{' '}
                    </a>
                    <span>|</span>
                    <a
                        className={classes.footerLegalLinks}
                        href={`${baseUrl}/terms/`}
                        data-layer-push-event="FooterClick|Footer|Click|Terms of Use"
                    >
                        Terms of Use
                    </a>
                    <span>|</span>
                    <a
                        className={classes.footerLegalLinks}
                        href={`${baseUrl}/terms-cart/`}
                        data-layer-push-event="FooterClick|Footer|Click|Terms of Service"
                    >
                        Terms of Service
                    </a>
                    <span>|</span>
                    <a
                        className={classes.footerLegalLinks}
                        href={`${baseUrl}/accessibility-statement/`}
                        data-layer-push-event="FooterClick|Footer|Click|Accessibility Statement"
                    >
                        Accessibility Statement
                    </a>
                    <span>|</span>
                    <a
                        className={classes.footerLegalLinks}
                        href={`${baseUrl}/privacy-policy/`}
                        data-layer-push-event="FooterClick|Footer|Click|Privacy Policy"
                    >
                        Privacy Policy
                    </a>
                    <a
                        className={classes.footerLegalLinks}
                        href={`${baseUrl}/cardholder-agreement/`}
                        data-layer-push-event="FooterClick|Footer|Click|Cardholder Agreement"
                    >
                        Cardholder Agreement
                    </a>
                </div>
                <div className={`${classes.siteFooterRow}`}>
                    <div className={`${classes.siteFooterCol}`}>
                        <p
                            className={classes.visaFooterDisclaimer}
                            data-sutton-terms
                        >
                            The Gift Card Granny Visa® Gift Card and the Digital
                            Visa eGift Card are issued by Sutton Bank®, Member
                            FDIC, pursuant to a license from Visa U.S.A. Inc.
                            Visa is a registered trademark of Visa, U.S.A. Inc.
                            All other trademarks and service marks belong to
                            their respective owners. The Visa Gift Card can be
                            used everywhere Visa debit cards are accepted in the
                            US. No cash or ATM access. The Digital Visa eGift
                            Card can be redeemed at internet merchants
                            everywhere Visa debit cards are accepted in the US.
                            No cash or ATM access. The Gift Card Granny
                            Mastercard® Gift Card and Digital Mastercard eGift
                            Card are issued by Sutton Bank, Member FDIC,
                            pursuant to a license by Mastercard International
                            Incorporated. Use your Mastercard Gift Card and
                            Digital Mastercard eGift Card everywhere Debit
                            Mastercard is accepted in the US. Mastercard is a
                            registered trademark, and the circles design is a
                            trademark of Mastercard International Incorporated.
                        </p>
                    </div>
                </div>
                <div className={`${classes.siteFooterRow}`}>
                    <div className={`${classes.siteFooterBadges}`}>
                        <div>
                            <div id="site-footer-digicert-container">
                                <NortonLogo
                                    digicertTestClassEnabled={
                                        digicertTestClassEnabled
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <a
                                href="https://www.bbb.org/us/pa/pittsburgh/profile/gift-cards/gift-card-granny-0141-71025047/#sealclick"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img
                                    className={`${classes.bbbImg} ${classes.lazyLoaded}`}
                                    loading="lazy"
                                    src="https://seal-westernpennsylvania.bbb.org/seals/blue-seal-120-61-bbb-71025047.png"
                                    alt="Gift Card Granny BBB Business Review"
                                    height="70"
                                    width="137"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
