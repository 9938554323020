import {
    SMB_LOGIN_REQUEST,
    SMB_LOGIN_RESPONSE,
    SET_SMB_DELIVERY_STYLE,
    SET_SMB_DETAILS_ENTRY_TYPE,
    SET_SMB_GRID_DATA,
    SET_SMB_SELECTED_PRODUCT,
    UPDATE_SMB_GRID_DATA_ROW,
    SMB_REGISTRATION_REQUEST,
    SMB_REGISTRATION_RESPONSE,
    CREATE_SMB_ORDER_RESPONSE,
    SMB_ORDER_CANCELLED,
    SMB_GET_CART_CONTENTS_RESPONSE,
    SMB_ADD_TO_CART,
    SET_SMB_SELECTED_GIFT_TEMPLATE,
    SMB_SET_CART_FLYOUT_MODE,
    SMB_MARK_CART_ITEM_FOR_DELETION,
    SMB_ITEMS_SYNCED_TO_BACKEND,
    SMB_CHECK_LOGIN_RESPONSE,
    SMB_RESET_CART,
    SMB_SET_ORDER_STATUS,
    SMB_SET_EDIT_ITEM,
    SMB_EDIT_CART_ITEM_REQUEST,
    SMB_EDIT_CART_ITEM_RESPONSE,
    SMB_CLEAR_EDIT_SUCCESS,
    SMB_CANCEL_EDIT_ITEM,
    SMB_UPDATE_COBRAND_CARD,
    SMB_RESET_COBRAND_BUILDER,
    SMB_UPDATE_COBRAND_CARD_LOGO,
    SMB_UPDATE_COBRAND_CARD_LOGO_ZOOM,
    SMB_CHECK_LOGIN_REQUEST,
    GET_SMB_STATUS_RESPONSE,
    SMB_GET_CART_CONTENTS_REQUEST,
    CLEAR_SMB_REGISTRATION_ERROR,
    SET_CUSTOMIZE_STEP,
    SMB_LOGOUT,
    SET_SMB_CHECKOUT_STEP,
    SMB_INITIALIZE_COBRAND_CARD_LOGO_VALUES,
    SMB_UPDATE_COBRAND_CARD_LOGO_PLACEMENT_ERROR,
    SET_SMB_GUEST_USER_INFO,
    SET_SMB_ANTICIPATED_LOAD_VALUE,
    SET_SMB_ANTICIPATED_QUANTITY,
    SET_SMB_ALREADY_CHOSE_QTY_AND_LOAD_VALUE,
    SET_SMB_APPLY_ALL_OVERRIDES,
    SET_SMB_CARDS,
    SET_SMB_RELOAD_CARDS,
    SET_SMB_ANTICIPATED_NAME_ON_CARD,
    SET_SMB_ANTICIPATED_MESSAGE_ON_CARD,
    SET_SELECTED_CARD_STYLE,
    SET_SELECTED_DESIGN_TYPE,
    SET_SELECTED_CARD_NETWORK,
    SET_SELECTED_CARD_TYPE,
    CLEAR_SAVED_DESIGN,
} from '../actions/smbCart'
import {
    ORDER_STATUS,
    CART_SOURCE,
    DELIVERY_STYLE,
    SMB_CUSTOMIZE_STEP,
    SMB_CHECKOUT_STEP, CARD_TYPE,
} from '../components/SmallBusiness/SmbConstants'
import {
    DIGITAL_ATTRIBUTES,
    PHYSICAL_ATTRIBUTES,
} from '../components/SmallBusiness/OrderFlow/CustomizationGridAttributes'
import CobrandCard from '../components/Models/CobrandCard'
import { v4 as uuidv4 } from 'uuid'
import has from 'lodash/has'
import isNil from 'lodash/isNil'
import { SMB_DEFAULT_SYNC_ERROR_MESSAGE } from '../errorConstants'

export const initialState = {
    selectedProduct: null,
    gridData: [],
    user: null,
    guestUserInfo: {
        contactEmail: '',
        contactName: '',
    },
    isLoggedIn: false,
    loginIsLoading: false,
    loginError: null,
    checkLoginIsLoading: true,
    smbStatus: {
        user_id: null,
        user_email: null,
        smb_status: null,
        _token: null,
    },
    smbStatusError: null,
    smbStatusIsLoading: true,
    registrationIsLoading: false,
    registrationError: null,
    paymentMethods: [],
    orderStatus: ORDER_STATUS.INCOMPLETE,
    cancelReason: null,
    orderResponse: {
        order: null,
        cards: [],
    },
    itemsPayload: {
        source: CART_SOURCE,
        delivery_groups: [],
        cards: [],
    },
    cartContents: null,
    cartContentsAreLoading: true,
    cartFlyoutMode: {
        display: false,
        addingToCart: false,
        addedToCart: false,
    },
    cartItemMarkedForDeletion: {
        openModal: false,
        cartId: null,
        item: null,
    },
    syncError: null,
    editingCartItemDeliveryGroupId: null,
    editingFromPlaceOrder: false,
    cartItemsCustomizationData: [],
    editCartItemLoading: false,
    editCartItemError: null,
    editCartItemSuccess: false,
    cobrandCard: new CobrandCard(),
    customizeStep: SMB_CUSTOMIZE_STEP.INFORMATION_ADDING_TYPE_SELECTION,
    checkoutStep: SMB_CHECKOUT_STEP.SHIPPING,
    applyAllOverrides: {},
    smbCards: [],
    smbReloadCards: false,
    selectedCardStyle: null,
    selectedDesignType: null,
    selectedCardNetwork: 'visa',
    selectedCardType: CARD_TYPE.PLASTIC,
    anticipatedLoadValue: 0,
    anticipatedQuantity: 0,
    alreadyChoseQtyAndLoadValue: false,
    anticipatedNameOnCard: '',
    anticipatedMessageOnCard: '',
}

const resetRequestedFlag = (action) =>
    has(action, 'meta') &&
    !isNil(action.meta) &&
    !isNil(action.meta.resetRequested)
        ? { resetRequested: true }
        : {}

export default function smbCartReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SMB_CARDS:
            return {
                ...state,
                smbCards: [...action.payload],
            }
        case SET_SMB_RELOAD_CARDS:
            return {
                ...state,
                smbReloadCards: action.payload,
            }
        case SET_SMB_APPLY_ALL_OVERRIDES:
            return {
                ...state,
                applyAllOverrides: {
                    ...state.applyAllOverrides,
                    ...action.payload,
                },
            }
        case SET_SMB_SELECTED_GIFT_TEMPLATE:
            return {
                ...state,
                selectedProduct: {
                    ...state.selectedProduct,
                    selectedGiftTemplate: action.payload,
                },
            }
        case SMB_LOGIN_REQUEST:
            return { ...state, loginIsLoading: true }
        case SMB_LOGIN_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    loginError: action.payload,
                    loginIsLoading: false,
                }
            }
            return {
                ...state,
                loginError: null,
                loginIsLoading: false,
            }
        case SMB_REGISTRATION_REQUEST:
            return {
                ...state,
                registrationIsLoading: true,
                registrationError: null,
            }
        case SMB_REGISTRATION_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    registrationError: action.payload,
                    registrationIsLoading: false,
                }
            }
            return {
                ...state,
                registrationError: null,
                registrationIsLoading: false,
            }
        case CLEAR_SMB_REGISTRATION_ERROR:
            return {
                ...state,
                registrationError: null,
            }
        case SET_SMB_SELECTED_PRODUCT:
            if (action.payload === null) {
                return {
                    ...state,
                    selectedProduct: null,
                }
            }

            return {
                ...state,
                selectedProduct: {
                    ...(state.selectedProduct || {}),
                    product: action.payload.product,
                    selectedGiftTemplate:
                        state.selectedProduct?.selectedGiftTemplate || null,
                    deliveryStyle:
                        action.payload.deliveryStyle ||
                        has(state.selectedProduct, 'deliveryStyle')
                            ? state.selectedProduct.deliveryStyle
                            : null,
                    detailsEntryType:
                        action.payload.detailsEntryType ||
                        has(state.selectedProduct, 'detailsEntryType')
                            ? state.selectedProduct.detailsEntryType
                            : null,
                    savedDesign: action.payload?.savedDesign ?? null,
                    isVirtualProduct: state.selectedProduct?.product?.isVirtualProduct
                },
            }
        case SET_SMB_DELIVERY_STYLE:
            return {
                ...state,
                selectedProduct: {
                    ...state.selectedProduct,
                    deliveryStyle: action.payload,
                },
            }
        case SET_SMB_DETAILS_ENTRY_TYPE:
            return {
                ...state,
                selectedProduct: {
                    ...state.selectedProduct,
                    detailsEntryType: action.payload,
                },
            }
        case SET_SMB_ANTICIPATED_LOAD_VALUE:
            return {
                ...state,
                anticipatedLoadValue: action.payload,
            }
        case SET_SMB_ANTICIPATED_QUANTITY:
            return {
                ...state,
                anticipatedQuantity: action.payload,
            }
        case SET_SMB_ANTICIPATED_NAME_ON_CARD:
            return {
                ...state,
                anticipatedNameOnCard: action.payload,
            }
        case SET_SMB_ANTICIPATED_MESSAGE_ON_CARD:
            return {
                ...state,
                anticipatedMessageOnCard: action.payload,
            }
        case SET_SMB_ALREADY_CHOSE_QTY_AND_LOAD_VALUE:
            return {
                ...state,
                alreadyChoseQtyAndLoadValue: action.payload,
            }
        case SET_SMB_GRID_DATA:
            return {
                ...state,
                gridData: action.payload,
            }
        case UPDATE_SMB_GRID_DATA_ROW:
            if (action.payload && action.payload.removeAddressFields) {
                return {
                    ...state,
                    gridData: state.gridData.map((row) => {
                        let rowData = row
                        if (row.hasOwnProperty('address')) {
                            const { address, ...restOfRowData } = row
                            rowData = restOfRowData
                        }
                        if (row.hasOwnProperty('emailAddress')) {
                            const { emailAddress, ...restOfRowData } = row
                            rowData = restOfRowData
                        }
                        return rowData
                    }),
                }
            }
            return {
                ...state,
            }
        case CREATE_SMB_ORDER_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    orderError: action.payload.errors,
                }
            }
            return {
                ...state,
                orderStatus: ORDER_STATUS.COMPLETE,
                orderResponse: {
                    order: action.payload.order,
                    cards: action.payload.cards,
                },
            }
        case SMB_ORDER_CANCELLED:
            return {
                ...state,
                orderStatus: ORDER_STATUS.CANCELLED,
                cancelReason: action.payload.cancelReason,
                orderResponse: {
                    order: action.payload.order,
                    cards: action.payload.cards,
                },
            }
        case SMB_SET_ORDER_STATUS:
            return {
                ...state,
                orderStatus: action.payload,
            }
        case SMB_GET_CART_CONTENTS_REQUEST:
            return {
                ...state,
                cartContentsAreLoading: true,
            }
        case SMB_GET_CART_CONTENTS_RESPONSE:
            if (action.meta?.status === 204) {
                return {
                    ...state,
                    cartContents: null,
                    cartItemsCustomizationData: [],
                    cartContentsAreLoading: false,
                }
            }
            if (action.error || !has(action.payload, 'cart')) {
                return {
                    ...state,
                    cartContentsAreLoading: false,
                }
            }
            return {
                ...state,
                cartContents: { ...action.payload.cart },
                cartItemsCustomizationData:
                    getCustomizationDataFromCartContents(action.payload.cart),
                cartContentsAreLoading: false,
            }
        case SMB_ADD_TO_CART:
            return {
                ...state,
                itemsPayload: {
                    ...state.itemsPayload,
                    delivery_groups: [
                        ...(state.itemsPayload.delivery_groups || []),
                        action.payload.newDeliveryGroup,
                    ],
                    cards: [
                        ...(state.itemsPayload.cards || []),
                        ...action.payload.newCards,
                    ],
                },
                ...resetRequestedFlag(action),
            }
        case SMB_RESET_CART:
            return {
                ...state,
                cartContents: null,
                cartFlyoutMode: {
                    ...state.cartFlyoutMode,
                    addedToCart: false,
                },
            }
        case SMB_MARK_CART_ITEM_FOR_DELETION:
            return {
                ...state,
                cartItemMarkedForDeletion: {
                    openModal: action.payload.openModal,
                    cartId: action.payload.cartId,
                    item: action.payload.item,
                },
            }
        case SMB_SET_CART_FLYOUT_MODE:
            return {
                ...state,
                cartFlyoutMode: {
                    ...state.cartFlyoutMode,
                    ...action.payload,
                },
            }
        case SMB_ITEMS_SYNCED_TO_BACKEND:
            let removingFromCart = false
            if (has(action.meta, 'removingFromCart')) {
                removingFromCart = action.meta.removingFromCart
            }
            return action.error || !has(action.payload, 'cart')
                ? {
                      ...state,
                      itemsPayload: {
                          source: CART_SOURCE,
                          delivery_groups: [],
                          cards: [],
                      },
                      syncError: {
                          code: action.payload.error_code,
                          message:
                              action.payload.message ||
                              SMB_DEFAULT_SYNC_ERROR_MESSAGE,
                          errors: action.payload?.errors,
                          errorUpdatingDeliveryGroupShippingInfo:
                              action?.meta
                                  ?.errorUpdatingDeliveryGroupShippingInfo,
                      },
                  }
                : !removingFromCart
                ? {
                      ...state,
                      cartContents: { ...action.payload.cart },
                      cartItemsCustomizationData:
                          getCustomizationDataFromCartContents(
                              action.payload.cart
                          ),
                      itemsPayload: {
                          ...state.itemsPayload,
                          delivery_groups: [],
                          cards: [],
                      },
                      gridData: [], // only reset if add to cart sync was successful
                      syncError: null,
                  }
                : {
                      ...state,
                      cartContents: { ...action.payload.cart },
                      syncError: null,
                  }
        case GET_SMB_STATUS_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    smbStatusError: action.payload,
                    smbStatus: {},
                    smbStatusIsLoading: false,
                    smbSubmitSuccess: false,
                }
            }
            return {
                ...state,
                smbStatus: action.payload.data,
                smbStatusError: null,
                smbStatusIsLoading: false,
            }
        case SMB_CHECK_LOGIN_REQUEST:
            return {
                ...state,
                checkLoginIsLoading: true,
            }
        case SMB_CHECK_LOGIN_RESPONSE:
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                user: action.payload.user,
                checkLoginIsLoading: false,
            }
        case SET_SMB_GUEST_USER_INFO:
            return {
                ...state,
                guestUserInfo: action.payload,
            }
        case SMB_SET_EDIT_ITEM:
            return {
                ...state,
                editingCartItemDeliveryGroupId:
                    action.payload.editingCartItemDeliveryGroupId,
                editingFromPlaceOrder: action.payload.editingFromPlaceOrder,
                editCartItemError: null,
                selectedProduct: getCustomizationProductData(
                    state.cartItemsCustomizationData,
                    action.payload.editingCartItemDeliveryGroupId
                ),
                gridData: getCustomizationGridData(
                    state.cartItemsCustomizationData,
                    action.payload.editingCartItemDeliveryGroupId
                ),
            }
        case SMB_CANCEL_EDIT_ITEM:
            return {
                ...state,
                editingCartItemDeliveryGroupId: null,
                gridData: [],
                editCartItemError: null,
                editCartItemLoading: false,
                editCartItemSuccess: false,
            }
        case SMB_EDIT_CART_ITEM_REQUEST:
            return {
                ...state,
                itemsPayload: {
                    ...state.itemsPayload,
                    delivery_groups: [
                        ...(state.itemsPayload.delivery_groups || []),
                        action.payload.newDeliveryGroup,
                    ],
                    cards: [
                        ...(state.itemsPayload.cards || []),
                        ...action.payload.newCards,
                    ],
                },
                editCartItemLoading: true,
                editCartItemError: null,
                editCartItemSuccess: false,
            }
        case SMB_EDIT_CART_ITEM_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    editCartItemError: action.payload,
                    editCartItemLoading: false,
                    editCartItemSuccess: false,
                }
            }
            return {
                ...state,
                editCartItemError: null,
                editCartItemLoading: false,
                editCartItemSuccess: true,
                editingCartItemDeliveryGroupId: null,
            }
        case SMB_CLEAR_EDIT_SUCCESS:
            return { ...state, editCartItemSuccess: false }
        case SMB_UPDATE_COBRAND_CARD:
            return {
                ...state,
                cobrandCard: action.payload,
            }
        case SMB_UPDATE_COBRAND_CARD_LOGO:
            return {
                ...state,
                cobrandCard: {
                    ...state.cobrandCard,
                    logo: action.payload,
                },
            }
        case SMB_UPDATE_COBRAND_CARD_LOGO_ZOOM:
            return {
                ...state,
                cobrandCard: {
                    ...state.cobrandCard,
                    logoZoom: action.payload,
                },
            }
        case SMB_RESET_COBRAND_BUILDER:
            return {
                ...state,
                cobrandCard: new CobrandCard(),
            }
        case SMB_INITIALIZE_COBRAND_CARD_LOGO_VALUES:
            // Initial logo zoom should be max
            const { maxLogoZoom, minLogoX, minLogoY } = action.payload
            return {
                ...state,
                cobrandCard: {
                    ...state.cobrandCard,
                    maxLogoZoom,
                    logoZoom: maxLogoZoom,
                    logoX: minLogoX,
                    logoY: minLogoY,
                },
            }
        case SMB_UPDATE_COBRAND_CARD_LOGO_PLACEMENT_ERROR:
            return {
                ...state,
                cobrandCard: {
                    ...state.cobrandCard,
                    logoPlacementError: action.payload,
                },
            }
        case SET_CUSTOMIZE_STEP:
            return {
                ...state,
                customizeStep: action.payload,
            }
        case SMB_LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
            }
        case SET_SMB_CHECKOUT_STEP: {
            return {
                ...state,
                checkoutStep: action.payload,
            }
        }
        case SET_SELECTED_CARD_STYLE: {
            return {
                ...state,
                selectedCardStyle: action.payload,
            }
        }
        case SET_SELECTED_DESIGN_TYPE: {
            return {
                ...state,
                selectedDesignType: action.payload,
            }
        }
        case SET_SELECTED_CARD_NETWORK: {
            return {
                ...state,
                selectedCardNetwork: action.payload.toLowerCase(),
            }
        }
        case SET_SELECTED_CARD_TYPE: {
            return {
                ...state,
                selectedCardType: action.payload.toLowerCase(),
            }
        }
        case CLEAR_SAVED_DESIGN: {
            return {
                ...state,
                selectedProduct: {
                    ...state.selectedProduct,
                    savedDesign: null,
                },
            }
        }
        default:
            return state
    }
}

// Gets gridData for for cart items for the given deliveryGroupId
function getCustomizationGridData(dataItems, deliveryGroupId) {
    return (
        dataItems.filter(
            (item) => item.deliveryGroup.uuid === deliveryGroupId
        )[0]?.gridData || []
    )
}

// Gets selectedProduct data for cart items for the given deliveryGroupId
function getCustomizationProductData(dataItems, deliveryGroupId) {
    const dataItem = dataItems.filter(
        (item) => item.deliveryGroup.uuid === deliveryGroupId
    )[0]
    return {
        product: dataItem?.product || null,
        deliveryStyle: dataItem?.deliveryGroup.delivery_style || null,
        detailsEntryType: dataItem?.detailsEntryType || null,
        selectedGiftTemplate: dataItem?.selectedGiftTemplate || null,
    }
}

// Use the cart response object to build a data structure that can be used
// to edit cart items.
function getCustomizationDataFromCartContents(cart) {
    return cart.delivery_groups.map((deliveryGroup) => {
        const deliveryGroupCards = cart.cart_items.filter((item) => {
            return item.delivery_group_uuid === deliveryGroup.uuid
        })
        const individualDeliveryStyle =
            deliveryGroup.delivery_style === DELIVERY_STYLE.INDIVIDUAL ||
            deliveryGroup.delivery_style === DELIVERY_STYLE.INDIVIDUAL_EMAIL
        const {
            wcp_product: { card_image_url, ...restOfWcpProduct },
        } = deliveryGroupCards[0].custom_card_details
        return {
            deliveryGroup: deliveryGroup,
            detailsEntryType: 'manual',
            product: {
                cobrand_uuid: deliveryGroupCards[0].cobrand_uuid ?? undefined,
                card_image_url: deliveryGroupCards[0].custom_card_image,
                ...restOfWcpProduct,
            },
            selectedGiftTemplate: deliveryGroupCards[0].on_demand_gift_template,
            gridData: deliveryGroupCards.map((card) => {
                if (card.plastic) {
                    const gridRowData = {
                        rowId: uuidv4(),
                        [PHYSICAL_ATTRIBUTES.AMOUNT.name]:
                            card.custom_card_details.load_value,
                        [PHYSICAL_ATTRIBUTES.NAME_ON_CARD.name]:
                            card.custom_card_details.recipient_name,
                        [PHYSICAL_ATTRIBUTES.CARD_MESSAGE.name]:
                            card.custom_card_details.emboss_message,
                        [PHYSICAL_ATTRIBUTES.CARRIER_TO.name]:
                            card.custom_card_details.shipping_name,
                        [PHYSICAL_ATTRIBUTES.CARRIER_FROM.name]:
                            card.custom_card_details.carrier_from,
                        [PHYSICAL_ATTRIBUTES.CARRIER_MESSAGE.name]:
                            card.custom_card_details.carrier_message,
                    }
                    if (individualDeliveryStyle) {
                        gridRowData[PHYSICAL_ATTRIBUTES.SHIP_TO.name] = {
                            name: card.custom_card_details.shipping_name,
                            addressLine1:
                                card.custom_card_details.address_line1,
                            addressLine2:
                                card.custom_card_details.address_line2,
                            city: card.custom_card_details.city,
                            state: card.custom_card_details.state,
                            zip: card.custom_card_details.zip_code,
                        }
                    }
                    return gridRowData
                } else {
                    return {
                        rowId: uuidv4(),
                        [DIGITAL_ATTRIBUTES.AMOUNT.name]:
                            card.custom_card_details.load_value,
                        [DIGITAL_ATTRIBUTES.FULL_NAME.name]:
                            card.custom_card_details.recipient_name,
                        [DIGITAL_ATTRIBUTES.EMAIL_MESSAGE.name]:
                            card.custom_card_details.recipient_message,
                        [DIGITAL_ATTRIBUTES.EMAIL_ADDRESS.name]:
                            card.custom_card_details.recipient_email,
                        [DIGITAL_ATTRIBUTES.SENDER_NAME.name]: card.sender_name,
                    }
                }
            }),
        }
    })
}
