import makeStyles from '@material-ui/core/styles/makeStyles'
import {
    Grid,
    Link,
    Typography,
    Button,
    MenuItem,
    Divider,
} from '@material-ui/core'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined'
import { pushToDataLayer } from '../../../utils/dataLayer'
import MobileMenuPhoneIcon from '../Icons/MobileMenuPhoneIcon'
import MobileMenuTextIcon from '../Icons/MobileMenuTextIcon'

const useStyles = ({ layout }) =>
    makeStyles((theme) => ({
        popoverLink: {
            display: 'flex',
            gap: 15,
            margin: '5px 0 5px 0',
            fontSize: '0.95rem',
            color: '#36196C',
            textUnderlineOffset: '1px',
            paddingLeft: '1em',
            width: '100%',
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        popoverLinkText: {
            fontSize: '0.95rem',
        },
        helpCenter: {
            padding: '0 10px',
        },
        helpCenterContainer: {
            padding: `0 0 ${layout === 'desktop' ? '1rem' : '.5rem'} 0`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: layout === 'desktop' ? 'center' : 'flex-start',
            margin: `${layout === 'desktop' ? '0rem' : '.5rem'} auto 0 .5rem`,
        },
        helpCenterHeader: {
            color: theme.palette.primary.dark,
            fontSize: '14px',
            margin: 0,
            fontWeight: '700',
        },
        helpCenterSubHeader: {
            color: '#374250',
            maxWidth: 150,
            fontSize: '12px',
            [theme.breakpoints.up('md')]: {
                maxWidth: '100%',
            },
        },
        helpCenterButtonContainer: {
            display: 'flex',
            gap: 20,
            marginTop: 15,
            marginBottom: 5,
            flexDirection: layout === 'mobile' ? 'column' : 'row',
        },
        helpCenterButton: {
            display: 'flex',
            fontSize: '1rem',
            color: '#333333',
            border: `4px solid ${theme.palette.secondary.main}`,
            padding: '.5rem 1.75rem',
            borderRadius: 81,
            textTransform: 'none',
            maxWidth: '142px',
            height: '64px',
        },
        helpCenterButtonIcon: {
            marginTop: 9,
            marginRight: 5,
        },
        helpCenterContactRow: {
            display: 'flex',
            marginTop: 15,
            marginBottom: 15,
            flexDirection: layout === 'mobile' ? 'column' : 'row',
            justifyContent: 'space-between',
            maxWidth: '20rem',
            gap: layout === 'mobile' ? 15 : 0,
        },
        helpCenterContact: {
            display: 'flex',
            gap: 5,
            color: '#374250',
        },
        helpCenterLinks: {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            fontSize: '10px',
            fontWeight: 'bold',
            marginLeft: 10,
        },
        helpCenterFaqHeader: {
            color: theme.palette.primary.main,
            padding: '.75rem 0 .25rem 2rem',
            fontWeight: 'bold',
        },
    }))

const HeaderHelpCenter = ({ layout, baseUrl }) => {
    const classes = useStyles({ layout })()

    const toggleAdaChat = () => {
        if (window.adaEmbed && window.adaEmbed.toggle) {
            window.adaEmbed.toggle()
        } else {
            window.open(
                'https://help.giftcardgranny.com/hc/en-us?source=site',
                '_blank'
            )
        }
    }

    return (
        <div className={classes.helpCenter}>
            <div className={classes.helpCenterContainer}>
                <div>
                    <Typography className={classes.helpCenterHeader}>
                        Have a question?
                    </Typography>

                    <Typography className={classes.helpCenterSubHeader}>
                        Call or chat with our Support Team
                    </Typography>
                    <div className={classes.helpCenterContactRow}>
                        <Link
                            href={'tel:+18556606847'}
                            className={classes.helpCenterContact}
                        >
                            <MobileMenuPhoneIcon />
                            <div>
                                <Typography style={{ fontSize: '10px' }}>
                                    Toll Free
                                </Typography>
                                <Typography
                                    style={{
                                        marginTop: 4,
                                        fontSize: '14px',
                                        fontWeight: '700',
                                    }}
                                >
                                    (855) 660-6847
                                </Typography>
                            </div>
                        </Link>

                        <Link
                            href={'sms:+14129124414'}
                            className={classes.helpCenterContact}
                        >
                            <MobileMenuTextIcon />
                            <div>
                                <Typography style={{ fontSize: '10px' }}>
                                    Text
                                </Typography>
                                <Typography
                                    style={{
                                        marginTop: 4,
                                        fontSize: '14px',
                                        fontWeight: '700',
                                    }}
                                >
                                    (412) 912-4414
                                </Typography>
                            </div>
                        </Link>
                    </div>

                    <div className={classes.helpCenterButtonContainer}>
                        <Button
                            className={classes.helpCenterButton}
                            href={'mailto:support@giftcardgranny.zendesk.com'}
                        >
                            <div className={classes.helpCenterButtonIcon}>
                                <EmailOutlinedIcon />
                            </div>
                            <div
                                style={{ fontSize: '12px', textWrap: 'nowrap' }}
                            >
                                Email Us
                            </div>
                        </Button>
                        <Button
                            className={classes.helpCenterButton}
                            onClick={toggleAdaChat}
                        >
                            <div className={classes.helpCenterButtonIcon}>
                                <QuestionAnswerOutlinedIcon />
                            </div>
                            <div
                                style={{ fontSize: '12px', textWrap: 'nowrap' }}
                            >
                                Chat Live
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
            {layout === 'desktop' && (
                <Divider style={{ marginBottom: '6px' }} />
            )}
            <Grid
                container
                spacing={layout === 'mobile' ? 1 : 4}
                style={{
                    flexDirection: layout === 'mobile' ? 'column' : 'row',
                    justifyContent:
                        layout === 'mobile' ? 'flex-start' : 'center',
                    padding:
                        layout === 'mobile' ? '0rem' : '.5rem 1rem 1rem .5rem',
                }}
            >
                <Grid item>
                    <Link
                        className={classes.helpCenterLinks}
                        href={'https://help.giftcardgranny.com/hc/en-us'}
                    >
                        Help Center
                    </Link>
                </Grid>
                {layout === 'mobile' && (
                    <Divider
                        style={{
                            marginLeft: '14px',
                            marginTop: '3px',
                            width: '54px',
                        }}
                    />
                )}
                <Grid item>
                    <Link
                        className={classes.helpCenterLinks}
                        href={`${baseUrl}/orderstatus/`}
                    >
                        Track Order
                    </Link>
                </Grid>
                {layout === 'desktop' && (
                    <Grid item>
                        <Link
                            className={classes.helpCenterLinks}
                            href={`${baseUrl}/activate/`}
                        >
                            Activate card
                        </Link>
                    </Grid>
                )}
            </Grid>

            {layout === 'desktop' && (
                <>
                    <Divider style={{ marginBottom: '6px' }} />

                    <Typography className={classes.helpCenterFaqHeader}>
                        Frequently Asked
                    </Typography>

                    {[
                        {
                            href: 'https://help.giftcardgranny.com/hc/en-us/articles/1500007938442-How-do-I-view-my-transaction-history',
                            text: 'How do I view my transaction history?',
                            analyticsEvent: [
                                'HeaderClick',
                                'Header',
                                'Click',
                                'How do I view my transaction history',
                            ],
                        },
                        {
                            href: 'https://help.giftcardgranny.com/hc/en-us/articles/1500008670321-My-gift-card-is-expired-what-do-I-do',
                            text: 'My gift card is expired, what do I do?',
                            analyticsEvent: [
                                'HeaderClick',
                                'Header',
                                'Click',
                                'My gift card is expired, what do I do',
                            ],
                        },
                        {
                            href: 'https://help.giftcardgranny.com/hc/en-us/articles/1500007939341-Common-reasons-why-your-card-is-not-working',
                            text: 'Common reasons why your card is not working',
                            analyticsEvent: [
                                'HeaderClick',
                                'Header',
                                'Click',
                                'Common reasons why your card is not working',
                            ],
                        },
                        {
                            href: 'https://help.giftcardgranny.com/hc/en-us/articles/1500008644142-I-have-not-received-my-order',
                            text: 'I have not received my order',
                            analyticsEvent: [
                                'HeaderClick',
                                'Header',
                                'Click',
                                'I have not received my order',
                            ],
                        },
                        {
                            href: 'https://help.giftcardgranny.com/hc/en-us/articles/360061601294-How-do-I-activate-my-Visa-or-Mastercard-gift-card-online',
                            text: 'How do I activate my Visa or Mastercard gift card online?',
                            analyticsEvent: [
                                'HeaderClick',
                                'Header',
                                'Click',
                                'How do I activate my Visa or Mastercard gift card online',
                            ],
                        },
                    ].map((link, key) => (
                        <MenuItem key={key}>
                            <Link
                                href={link.href}
                                className={classes.popoverLink}
                                onClick={() =>
                                    pushToDataLayer(link.analyticsEvent)
                                }
                                target={'_blank'}
                            >
                                <Typography className={classes.popoverLinkText}>
                                    {link.text}
                                </Typography>
                            </Link>
                        </MenuItem>
                    ))}
                </>
            )}
        </div>
    )
}

export default HeaderHelpCenter
